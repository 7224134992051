import PropTypes from 'prop-types';
import { Button } from '@vartanainc/design-system';
import connectedIcon from '../../../assets/badge_connected.svg';
// import Button from '../../../components/Button';

/**
 * The small tile shown for each Integration
 */
function IntegrationCard({
  integration,
  onClick,
  onDeactivate,
  buttonLabel,
  imageIcon,
  inSettingsPage,
}) {
  const { id, title, isActive } = integration;

  return (
    <div
      id={id}
      className={`
        flex flex-col justify-between items-center border border-vartana-gray-20
        shadow-integration-card rounded-md px-6 py-8 gap-2 mx-auto w-full h-[16rem]
        ${isActive && 'bg-vartana-light-blue-20'}
      `}
    >
      <div className="flex justify-center items-center h-14">{imageIcon}</div>
      <p className="my-2 text-vartana-gray-70 vp-text-link-bold">{title}</p>
      {isActive ? (
        <>
          {inSettingsPage ? (
            <>
              <Button size="x-small" onClick={onClick} variant="outlined">
                Reconfigure
              </Button>
              <Button size="x-small" onClick={() => onDeactivate(integration)}>
                Deactivate
              </Button>
            </>
          ) : (
            <img className="mt-4" src={connectedIcon} alt="Connected Badge" />
          )}
        </>
      ) : (
        <Button size="x-small" onClick={onClick}>
          {buttonLabel}
        </Button>
      )}
      {/** Need to check background color here */}
      {/** className={`
      bg-vartana-light-blue-20 text-vartana-blue-60 vp-btn-text 
      mt-2 px-2 py-4 w-10/12 rounded-lg focus:outline-none focus:ring-0 focus:border-vartana-blue-60
      `} */}
    </div>
  );
}

IntegrationCard.propTypes = {
  integration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    iconUrl: PropTypes.string.isRequired,
  }).isRequired,
  buttonLabel: PropTypes.string.isRequired,
  imageIcon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func,
  inSettingsPage: PropTypes.bool,
};

IntegrationCard.defaultProps = {
  onDeactivate: () => {},
  inSettingsPage: false,
};

export default IntegrationCard;
