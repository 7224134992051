import { useState } from 'react';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { Typography } from '@vartanainc/design-system';
import OrderProposalV2 from './OrderProposalV2';
import { ReactComponent as AddAnotherIcon } from '../../assets/circle_cross.svg';
import { ReactComponent as AddAnotherIconDisabled } from '../../assets/circle_cross_disabled.svg';
import {
  getCloneOrderValues,
  isProposalEmpty,
  MAX_ALLOWED_PROPOSALS,
} from './order.constants';
import RemoveProposalModal from '../../components/Modals/RemoveProposalModal';
/*
 This `OrderForm` displays a list of order proposals. It's being used in 3 places:
 - VD: Create order page
 - Expanded App: Create order page
 - Edit Order page (common for both VD & Expanded app)
 */
export function OrderForm({
  formik,
  selectedCustomer,
  customerDetailsLoading,
  isVartanaFinancingCheckBoxDisabled,
  productConfig,
  approvedOffers,
  defaultProposalValues,
  updateProposalErrors,
  isEditOrder = false,
  selectedProposalNumber,
  customScheduleFields,
  deferApprovedNetTerms,
  installmentApprovedCreditTerms,
  enableOrderFormLoading,
  disableOrderFormLoading,
}) {
  const [isRemoveProposalDialogOpen, setIsRemoveProposalDialogOpen] = useState(false);
  const [selectedProposalIndex, setSelectedProposalIndex] = useState(null);
  // eslint-disable-next-line react/prop-types
  const { isCustomSchedule } = productConfig;

  const handleRemoveProposal = (index) => {
    setIsRemoveProposalDialogOpen(true);
    setSelectedProposalIndex(index);
  };

  const removeSelectedProposal = (remove) => {
    remove(selectedProposalIndex);
    setIsRemoveProposalDialogOpen(false);
    setSelectedProposalIndex(null);
  };

  const enableAddProposal = formik.isValid && !isCustomSchedule;

  return (
    <>
      <FieldArray name="proposals">
        {({ remove, push }) => (
          <>
            {formik.values.proposals.length
              ? formik.values.proposals.map((proposal, index) => (
                <OrderProposalV2
                  key={proposal.key}
                  id={proposal.key}
                  index={index}
                  showOnRemove={
                    formik.values.proposals.length > 1 &&
                      proposal.number !== selectedProposalNumber
                  }
                  formik={formik}
                  onRemove={() =>
                    isProposalEmpty(proposal)
                      ? remove(index)
                      : handleRemoveProposal(index)}
                  selectedCustomer={selectedCustomer}
                  formValues={proposal}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                  customerDetailsLoading={customerDetailsLoading}
                  approvedOffers={approvedOffers}
                  isVartanaFinancingCheckBoxDisabled={
                    isVartanaFinancingCheckBoxDisabled
                  }
                  productConfig={productConfig}
                  updateProposalErrors={updateProposalErrors}
                  isEditOrder={isEditOrder}
                  customScheduleFields={customScheduleFields}
                  deferApprovedNetTerms={deferApprovedNetTerms}
                  installmentApprovedCreditTerms={installmentApprovedCreditTerms}
                  enableOrderFormLoading={enableOrderFormLoading}
                  disableOrderFormLoading={disableOrderFormLoading}
                />
              ))
              : null}
            <>
              {formik.values.proposals.length < MAX_ALLOWED_PROPOSALS ? (
                <div className="w-full">
                  <button
                    type="button"
                    className="flex items-center gap-1 w-fit text-vartana-gray-60 disabled:text-vartana-gray-40"
                    disabled={!enableAddProposal}
                    onClick={() =>
                      push(getCloneOrderValues(formik.values, defaultProposalValues))}
                  >
                    {enableAddProposal ? (
                      <AddAnotherIcon className="w-8 h-8" />
                    ) : (
                      <AddAnotherIconDisabled className="w-8 h-8" />
                    )}
                    <Typography
                      variant="heading16"
                      bold
                      color={enableAddProposal ? 'color-blue-120' : 'color-gray-100'}
                    >
                      Add another proposal
                    </Typography>
                  </button>
                </div>
              ) : null}
              <RemoveProposalModal
                open={isRemoveProposalDialogOpen}
                handleRemove={() => removeSelectedProposal(remove)}
                handleClose={() => setIsRemoveProposalDialogOpen(false)}
              />
            </>
          </>
        )}
      </FieldArray>
    </>
  );
}

OrderForm.propTypes = {
  formik: PropTypes.shape({
    isValid: PropTypes.bool,
    dirty: PropTypes.bool,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
      proposals: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  selectedCustomer: PropTypes.shape({
    buyerMinAllowedOrderAmount: PropTypes.number,
    number: PropTypes.string,
  }).isRequired,
  customerDetailsLoading: PropTypes.bool,
  isVartanaFinancingCheckBoxDisabled: PropTypes.bool.isRequired,
  productConfig: PropTypes.shape({}).isRequired,
  approvedOffers: PropTypes.shape({}).isRequired,
  defaultProposalValues: PropTypes.shape({}).isRequired,
  updateProposalErrors: PropTypes.func.isRequired,
  isEditOrder: PropTypes.bool,
  selectedProposalNumber: PropTypes.string,
  customScheduleFields: PropTypes.shape({
    tcv: PropTypes.string,
    billingFrequency: PropTypes.string,
    spiff: PropTypes.string,
    blindDiscount: PropTypes.string,
    term: PropTypes.number,
    paymentTerm: PropTypes.number,
  }),
  installmentApprovedCreditTerms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deferApprovedNetTerms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enableOrderFormLoading: PropTypes.func.isRequired,
  disableOrderFormLoading: PropTypes.func.isRequired,
};

OrderForm.defaultProps = {
  customerDetailsLoading: false,
  isEditOrder: false,
  selectedProposalNumber: '-1',
  customScheduleFields: {},
};
