import { ButtonV2, Typography } from '@vartanainc/design-system';
import Modal from 'react-modal';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FileType } from '../../pages/Orders/OrderTypes';
import SvgIcon from '../SvgIcon/SvgIcon';
import VartanaLoader from '../VartanaLoader/VartanaLoader';
import FileItem from '../FileItem/FileItem';
import { ReactComponent as FileTitleIcon } from '../../assets/file_icon.svg';
import './modal.scss';

function UploadDocsFromCrm({
  open = true,
  onClose,
  onSubmit,
  isLoading,
  files,
}: {
  open?: boolean;
  onClose: () => void;
  onSubmit: (selectedFiles) => void;
  isLoading: boolean;
  files: FileType[];
}): JSX.Element {
  const MAX_FILES_FOR_SCROLL = 4;
  const MAX_FILES_TO_SELECT = 5;
  const LOADER_TEXT = 'Give us a moment...';
  const [checkedFiles, setCheckedFiles] = useState<string[]>([]);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1200px)' });

  useEffect(() => {
    if (open) {
      // Disable scroll for the body when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Restore scroll for the body when modal is closed
      document.body.style.overflow = '';
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  const disabledFilesIds = useMemo(() => {
    if (checkedFiles.length === MAX_FILES_TO_SELECT) {
      return files.reduce<string[]>((acc, file) => {
        if (!checkedFiles.includes(file.ContentDocumentId)) {
          acc.push(file.ContentDocumentId);
        }
        return acc;
      }, []);
    }
    return [];
  }, [checkedFiles, files]);

  const handleOnClose = (): void => {
    onClose();
    setCheckedFiles([]);
  };

  const handleOnSubmit = (): void => {
    onSubmit(checkedFiles);
    setCheckedFiles([]);
  };

  const handleFileClick = (id: string): void => {
    setCheckedFiles((prev) =>
      prev.includes(id) ? prev.filter((fileId) => fileId !== id) : [...prev, id]
    );
  };

  const getFileItemListClassnames = (): string => {
    let classNames = 'flex flex-col max-h-[22.1rem] overflow-auto gap-2 pb-2';
    if (files?.length > MAX_FILES_FOR_SCROLL) {
      classNames += ' border-b-2 border-vartana-gray-40-v3 custom-negative-shadow';
    }
    return classNames;
  };

  const getModalContainerClassnames = (): string => {
    let classNames =
      'flex flex-col relative bg-white max-h-[35.375] min-h-[14.5rem] rounded-xl';
    if (isLoading) {
      classNames += ' w-80';
    } else {
      classNames += ' w-[43.75rem]';
    }
    return classNames;
  };

  return (
    <Modal
      isOpen={open}
      className={`modal-transparent ${
        isSmallScreen ? 'items-center' : 'pt-48 items-start'
      }  flex justify-center `}
      ariaHideApp={false}
    >
      <div className={getModalContainerClassnames()}>
        {!isLoading && (
          <div className="flex justify-end p-2">
            <button onClick={handleOnClose}>
              <SvgIcon
                name="close"
                width="1.5rem"
                height="1.5rem"
                fill="color-gray-100"
              />
            </button>
          </div>
        )}
        {isLoading || !files ? (
          <VartanaLoader
            loading={isLoading}
            fullscreen={false}
            containerClassName="rounded-xl bg-opacity-0 items-start"
            textColor="color-gray-140"
            text={<>{LOADER_TEXT}</>}
          />
        ) : (
          <div className="flex flex-col px-10 pb-10">
            <div className="flex flex-col gap-6">
              <div className="flex gap-2 items-center">
                <FileTitleIcon />
                <Typography variant="heading20" color="color-black-100">
                  Select documents for signature
                </Typography>
              </div>
              <div className={getFileItemListClassnames()}>
                {files?.map((file) => (
                  <FileItem
                    key={file.ContentDocumentId}
                    fileName={file?.ContentDocument?.Title}
                    id={file.ContentDocumentId}
                    isChecked={checkedFiles.includes(file.ContentDocumentId)}
                    isDisabled={disabledFilesIds.includes(file.ContentDocumentId)}
                    onClick={() => handleFileClick(file.ContentDocumentId)}
                    FileTimestamp={file?.SystemModstamp || ''}
                  />
                ))}
              </div>
            </div>
            <div className="flex items-center justify-between gap-4 mt-4">
              <ButtonV2
                variant={{ type: 'ghost', typography: 'paragraph14' }}
                text="Cancel"
                type="button"
                iconLeft="chevron_left"
                onClick={handleOnClose}
              />
              <ButtonV2
                variant={{ type: 'primary', typography: 'paragraph14' }}
                text="Submit"
                type="button"
                disabled={!checkedFiles.length}
                onClick={handleOnSubmit}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
export default UploadDocsFromCrm;
