import {
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { useLocation, useParams } from 'react-router-dom';
import { omit, get } from 'lodash';
import { Card, Typography } from '@vartanainc/design-system';

import { useMutation, useReactiveVar } from '@apollo/client';
import { useBeforeunload } from 'react-beforeunload';

import { WidgetContext } from '../../../../context/WidgetContext';
import FormInput from '../../../../components/FormInput';
import AutoLoad from '../../../../components/AutoLoad';

import { CREATE_CUSTOMER, UPDATE_CUSTOMER } from '../../../../graphql/queries/customer';
import { sessionVar } from '../../../../graphql/cache';
import { useDirectUploadFiles, useSetAddressFields } from '../../../../utils/hooks';
import AddressInputField from '../../../../designSystem/CompactFields/TypeAheadSelect';
import InputField from '../../../../designSystem/CompactFields/InputField';
import CompactNumericField from '../../../../designSystem/CompactFields/CompactNumericField';
import CompactDropdownInputField from '../../../../designSystem/CompactFields/CompactDropDown';
import {
  getCountryOptions,
  getStateOptions,
  getTermOptionsForCustomerCreation,
  handleCountrySelect,
} from '../../../../utils/helpers';
import { WidgetMetaContext } from '../../../../context/WidgetMetaContext/WidgetMetaContext';
import '../../../CommonWidgetV2/CommonWidget.scss';
import { CUSTOMER_FORM_FIELD_NAMES, TWO_LEVEL_DECIMAL } from '../widgetV2.constants';
import MultiCountryZipInput from '../../../../designSystem/MultiCountryZipInput/MultiCountryZipInput';
import { COUNTRY, COUNTRY_TO_CURRENCY_MAP } from '../../../../static';
import { zipSchema } from '../../../../constants/common.constants';
import { CUSTOMER_FORM_STEPS, isExtraLargeScreen } from '../widgetUtils';
import MultiFileUpload from '../../../../components/MultiFileUpload';
import { useValidateCustomer } from '../../../../hooks/CustomerValidation';
import { salesQuoteSchema } from '../../../../formikSchema/commonSchema';
import { useSessionValue } from '../../../../hooks/SessionValue';

const schema = yup.object().shape({
  name: yup
    .string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Company name is required'),
  legalName: yup.string(),
  street: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: zipSchema,
  maxUnits: yup.number().positive('Should be greater than 0'),
  dunsNumber: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .max(9, 'Must be exactly 9 digits')
    .min(9, 'Must be exactly 9 digits'),
});

const requestedAmountSchema = yup.object().shape({
  requestedAmount: yup
    .number()
    .required('Request amount is required')
    .positive('Should be greater than 0'),
  requestedTerm: yup.number().required('Requested term is required'),
});

const maxUnitSchema = yup.object().shape({
  maxUnits: yup
    .number()
    .positive('Should be greater than 0')
    .required('Max unit is required'),
});

interface CustomerFormProps {
  setCanSubmit: (canSubmit: boolean) => void;
  customerFormRef: Ref<unknown> | undefined;
  crmOpportunityId: string;
  crmAccountId: string;
  isCrm: boolean;
  isSignature?: boolean;
  initialCustomerValues: {
    city?: string;
    street?: string;
    state?: string;
    zip?: string;
    name?: string;
    country?: string;
    currency?: string;
  };
  activeStep: number;
  handleNextStep: () => void;
}

const HUBSPOT_FORM_PARAM = '&hubspotCustomerForm=true';

export function CustomerForm({
  setCanSubmit,
  customerFormRef,
  crmOpportunityId,
  crmAccountId,
  isCrm,
  isSignature = false,
  initialCustomerValues,
  activeStep,
  handleNextStep,
}: CustomerFormProps): JSX.Element {
  const { companyNumber } = useParams();
  const session = useReactiveVar(sessionVar);
  const [updateCustomer, { loading: updatingCustomer }] = useMutation(UPDATE_CUSTOMER);
  const [createCustomer, { loading: creatingCustomer }] = useMutation(CREATE_CUSTOMER);
  const metaContext = useContext(WidgetMetaContext);
  const activeTab = get(metaContext, 'activeTab', '-');
  const location = useLocation();
  const widgetContext = useContext(WidgetContext);
  const widthVariant = get(widgetContext, 'widthVariant', '');

  const showCountryField = get(
    session,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const supportedCountries = useMemo(() => {
    return get(session, 'session.user.company.product.enabledCountries', []);
  }, [session]);
  const supportedCurrencies = useMemo(() => {
    return get(session, 'session.user.company.product.enabledCurrencies', []);
  }, [session]);

  const [canSafelyExit, setCanSafelyExit] = useState(true);
  const hasRequestedAmount = useMemo(() => {
    // if an active tab is present, check if it has requested amount otherwise check all tabs
    if (activeTab) {
      return get(metaContext, `${activeTab}.useRequestedAmount`, false);
    }
    const hasTabWithRequestedAmount = Object.keys(metaContext || {}).some((tab) => {
      return get(metaContext, `${tab}.useRequestedAmount`, false);
    });
    return hasTabWithRequestedAmount;
  }, [activeTab, metaContext]);

  const [initialValues] = useState({
    number: null,
    name: '',
    legalName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    employeeCount: '',
    annualRevenue: '',
    maxUnits: '',
    requestedAmount: '',
    requestedTerm: '',
    contactNumber: null,
    addressId: null,
    country: showCountryField ? '' : supportedCountries[0],
    currency: showCountryField ? '' : supportedCurrencies[0],
    crmOpportunityId,
    crmAccountId,
    ...initialCustomerValues,
  });

  const countrySchema = yup.object().shape({
    // country's should be one of supported countries from Product
    country: yup
      .string()
      .required('Country is required')
      .test('is-supported-country', 'Country is not supported', (value) => {
        return supportedCountries.includes(value as never);
      }),
    currency: yup.string().required('Currency is required'),
  });

  useEffect(() => {
    // this will disable the submit button if the form is in the process of submitting
    setCanSubmit(!(updatingCustomer || creatingCustomer));
  }, [creatingCustomer, setCanSubmit, updatingCustomer]);

  const [attachSalesQuote] = useDirectUploadFiles();
  const [validateCustomer] = useValidateCustomer();

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      // Determine the values to be submitted based on the form conditions
      let valuesToBeSubmitted;
      if (isSignature) {
        // If it's a signature form, omit the max units, requested amount, and requested term fields
        valuesToBeSubmitted = omit(values, [
          CUSTOMER_FORM_FIELD_NAMES.MAX_UNITS,
          CUSTOMER_FORM_FIELD_NAMES.REQUESTED_AMOUNT,
          CUSTOMER_FORM_FIELD_NAMES.REQUESTED_TERM,
        ]);
      } else if (hasRequestedAmount) {
        // If it has a requested amount, omit the max units field
        valuesToBeSubmitted = omit(values, [CUSTOMER_FORM_FIELD_NAMES.MAX_UNITS]);
      } else {
        // Otherwise, omit the requested amount and requested term fields
        valuesToBeSubmitted = omit(values, [
          CUSTOMER_FORM_FIELD_NAMES.REQUESTED_AMOUNT,
          CUSTOMER_FORM_FIELD_NAMES.REQUESTED_TERM,
        ]);
      }

      // If the active step is the customer info step, validate the customer info
      if (activeStep === CUSTOMER_FORM_STEPS.customerInfo) {
        const errors = await validateCustomer(valuesToBeSubmitted);
        if (errors) {
          setErrors(errors);
        } else {
          handleNextStep();
        }
        return;
      }

      try {
        let responseData;
        if (companyNumber) {
          responseData = await updateCustomer({
            variables: valuesToBeSubmitted,
          });
        } else {
          responseData = await createCustomer({
            variables: omit(valuesToBeSubmitted, [
              'number',
              'contactNumber',
              'addressId',
              'docs',
            ]),
          });
        }
        const errors = get(responseData, 'errors', {});
        const hasErrors = Object.keys(errors).length > 0;

        if (!hasErrors) {
          const newCustomerId = get(responseData, 'data.createCustomer.id', null);
          const creditCheckId = get(
            responseData,
            'data.createCustomer.creditCheck.id',
            null
          );
          const { docs } = values;
          const attachedResource = {
            id: creditCheckId || newCustomerId,
            type: creditCheckId ? 'CreditEngine::CreditCheck' : 'Company',
          };
          const documentType = {
            documentType: 'sales_quote',
          };
          if (docs?.length)
            if (typeof attachSalesQuote === 'function') {
              await attachSalesQuote(docs, documentType, attachedResource);
            }
        }

        if (Object.keys(errors).length) {
          setErrors(errors);
        } else {
          // Remove the hubspotParamText from the location search
          // so that footer buttons are not effected by it on order form
          const url = location.search.replace(HUBSPOT_FORM_PARAM, '');
          setTimeout(() => {
            window.open(url, '_self');
          }, 0);
        }
        setCanSafelyExit(true);
      } catch (e) {
        console.error(e);
      }
    },
    [
      isSignature,
      hasRequestedAmount,
      companyNumber,
      updateCustomer,
      createCustomer,
      location.search,
      attachSalesQuote,
      activeStep,
      validateCustomer,
      handleNextStep,
    ]
  );

  const isSalesQuoteCompulsory = useSessionValue<boolean>(
    'session.user.company.salesQuoteRequiredAtCustomerCreation',
    false
  );

  // Define the validation schema based on the conditions
  const validationSchema = useMemo(() => {
    let schemaToUse = schema;
    if (!isSignature) {
      if (hasRequestedAmount) schemaToUse = schemaToUse.concat(requestedAmountSchema);
      // Combine the base schema with requestedAmountSchema
      else schemaToUse = schemaToUse.concat(maxUnitSchema); // Combine the base schema with maxUnitSchema
    }
    if (showCountryField) {
      schemaToUse = schemaToUse.concat(countrySchema);
    }
    if (isSalesQuoteCompulsory && activeStep === CUSTOMER_FORM_STEPS.documentUpload) {
      schemaToUse = schemaToUse.concat(salesQuoteSchema);
    }
    return schemaToUse;
  }, [
    countrySchema,
    hasRequestedAmount,
    isSignature,
    showCountryField,
    isSalesQuoteCompulsory,
    activeStep,
  ]);

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const termOptions = getTermOptionsForCustomerCreation(session);

  useEffect(() => {
    // whenever canada is selected as country, remove pay in full option from requested terms
    const { country, requestedTerm } = formikBag.values;
    if (country === COUNTRY.CA && requestedTerm === '0')
      formikBag.setFieldValue('requestedTerm', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.values.country]);

  useEffect(() => {
    if (showCountryField) {
      const countryCode = formikBag.values.country;
      const currency = COUNTRY_TO_CURRENCY_MAP[countryCode];
      if (currency) formikBag.setFieldValue('currency', currency);
    }
    // only run this effect when country changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.values.country]);

  useImperativeHandle(customerFormRef, () => ({
    formRef: formikBag,
    submitCustomerForm: formikBag.submitForm,
  }));
  const { isSubmitting, setFieldValue, validateField, handleSubmit } = formikBag;
  const setSelectedPlace = useSetAddressFields(
    setFieldValue,
    validateField,
    showCountryField
  );

  useBeforeunload((event) => {
    if (!canSafelyExit) {
      event.preventDefault();
    }
  });

  // If the active step is document upload, render the document upload form
  if (activeStep === CUSTOMER_FORM_STEPS.documentUpload)
    return (
      <div className={`h-[19.25rem] ${isCrm ? 'customer-form-container' : ''}`}>
        <Card
          padding="compact"
          title={(
            <Typography variant="heading16" color="color-blue-180" bold>
              Sales quote or other documents
              {!isSalesQuoteCompulsory && ' (optional)'}
            </Typography>
          )}
          parentContainerClassName={`progen-card self-baseline ${
            showCountryField ? 'mb-[1.5rem]' : ''
          }`}
          containerClassName="!pb-[1.12rem]"
          content={(
            <FormikProvider value={formikBag}>
              <div className="flex flex-col gap-1">
                <MultiFileUpload
                  uploadText="Click or drag PDF files to upload"
                  name="docs"
                  acceptMultipleFiles
                  showError={!!get(formikBag.errors, 'docs')}
                />
              </div>
            </FormikProvider>
          )}
        />
      </div>
    );

  return (
    <div className={`h-[19.25rem] ${isCrm ? 'customer-form-container' : ''}`}>
      <AutoLoad
        containerClassName="flex justify-center"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
        loading={false}
      >
        <Card
          padding="compact"
          title={(
            <Typography variant="heading16" color="color-blue-180" bold>
              Tell us about your customer
            </Typography>
          )}
          parentContainerClassName={`progen-card ${
            showCountryField ? 'mb-[1.5rem]' : ''
          }`}
          content={(
            <FormikProvider value={formikBag}>
              <form onSubmit={handleSubmit}>
                <fieldset disabled={isSubmitting}>
                  <div className="flex flex-col gap-y-4 pb-2">
                    <FormInput className="hidden" name="number" type="hidden" />
                    <InputField
                      name="name"
                      label="Company name"
                      showError={!!formikBag.errors.name}
                    />
                    <FormInput className="hidden" name="addressId" type="hidden" />
                    <AddressInputField
                      id="address"
                      name="street"
                      label="Address"
                      showError={!!formikBag.errors.street}
                      afterPlaceSelect={(tempSelectedPlace) =>
                        setSelectedPlace(tempSelectedPlace)}
                      countries={supportedCountries}
                    />
                    <div className="flex flex-row gap-x-[0.1875rem] city-input-field">
                      <div className="w-1/3">
                        <InputField
                          label="City"
                          placeholder=""
                          name="city"
                          showError={!!formikBag.errors.city}
                        />
                      </div>
                      <div className="state-dropdown w-1/3">
                        <CompactDropdownInputField
                          name="state"
                          label="State"
                          options={
                            getStateOptions(
                              supportedCountries,
                              !isExtraLargeScreen(widthVariant, isCrm)
                            ) || []
                          }
                          showError={!!formikBag.errors.state}
                          placeholder="Select"
                        />
                      </div>
                      <div className="w-1/3">
                        <MultiCountryZipInput
                          id="zip"
                          name="zip"
                          label="Zip"
                          compact
                          showError={!!formikBag.errors.zip}
                        />
                      </div>
                    </div>
                    {showCountryField && (
                      <div className="flex flex-row gap-x-[0.1875rem]">
                        <div className="w-1/2">
                          <CompactDropdownInputField
                            name="country"
                            label="Country"
                            options={getCountryOptions(supportedCountries) || []}
                            placeholder="Select"
                            showError={!!formikBag.errors.city}
                            onChange={(e) => handleCountrySelect(formikBag, e.value)}
                          />
                        </div>
                        <div className="w-1/2">
                          <InputField
                            label="Currency"
                            placeholder=""
                            name="currency"
                            disabled
                          />
                        </div>
                      </div>
                    )}
                    {!isSignature && (
                      <div className="flex flex-row gap-x-[0.1875rem]">
                        {hasRequestedAmount ? (
                          <>
                            <CompactNumericField
                              name="requestedAmount"
                              label="Requested amount"
                              placeholder="0"
                              // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get currency from product/credit appraisal
                              customPrefix="$"
                              showError={!!formikBag.errors.requestedAmount}
                              onValueChange={(e) =>
                                formikBag.setFieldValue('requestedAmount', e.floatValue)}
                              decimalScale={TWO_LEVEL_DECIMAL}
                              fixedDecimalScale={false}
                            />
                            <div className="request-term-dropdown w-full">
                              <CompactDropdownInputField
                                name="requestedTerm"
                                label="Requested term"
                                placeholder="select"
                                showError={!!formikBag.errors.requestedTerm}
                                options={termOptions}
                              />
                            </div>
                          </>
                        ) : (
                          <CompactNumericField
                            name="maxUnits"
                            label="Max units"
                            placeholder="0"
                            showError={!!formikBag.errors.maxUnits}
                            onValueChange={(e) =>
                              formikBag.setFieldValue('maxUnits', e.floatValue)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </fieldset>
              </form>
            </FormikProvider>
          )}
        />
      </AutoLoad>
    </div>
  );
}
