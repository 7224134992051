/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { get, isEmpty, startCase } from 'lodash';
import { Typography, Button, InputField } from '@vartanainc/design-system';

import { useReactiveVar, useMutation, useQuery } from '@apollo/client';
import { TABLE_EMPTY_PLACEHOLDER } from '../../constants/common.constants';
import { GET_ORDERS } from '../../graphql/queries/order';
import { GENERATE_ORDERS_CSV } from '../../graphql/mutations';
import {
  GET_VENDOR_BUYERS_DATA,
  GET_VENDOR_ORDERS_DATA,
} from '../../graphql/queries/vendor';

import Table from '../../components/Table';
import Loader from '../../components/Loader';
import ZeroState from '../../components/ZeroState/ZeroState';
import NoDataState from '../../components/NoDataState/NoDataState';
import TruncatedText from '../../components/TruncatedText/TruncatedText';

import {
  getStatusClass,
  formatCapital,
  getUSDateFormat,
  getTextForNumberOfDays,
  useVartanaFeatureFlags,
  showToast,
  titleize,
} from '../../utils/helpers';
import '../searchFilter.scss';
import { useLazyQuery } from '../../utils/hooks';
import { ACTION, RESOURCE } from '../../constants/permissions';
import { OrderStatusOptions } from '../../static';
import { ALLOWED_STATES_TO_EXPIRE } from './order.constants';

import { MainbodyContext } from '../../context/DashboardContext';

import generatingPlansIcon from '../../assets/generating_plans.svg';
import { HasPermission } from '../../components/HasPermission/HasPermission';
import usePermissions from '../../utils/hooks/permissions';
import { ReactComponent as NeedInfoIcon } from '../../assets/need_info_big.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import DropdownInput from '../../designSystem/DropdownInput/DropdownInput';
import { sessionVar } from '../../graphql/cache';
import { isHighlighted } from './order.utils';
import { CurrencyPill } from '../../components/Pill/CurrencyPill';

export function Orders() {
  const [, userHasPermission] = usePermissions();
  const navigate = useNavigate();
  const { csvExport: showCsvExport } = useVartanaFeatureFlags();
  const [searchParams] = useSearchParams();

  const [orders, setOrders] = useState([]);

  const { vendor } = useContext(MainbodyContext);

  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const [afterCursor, setAfterCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [companyName, setCompanyName] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [vendorName, setVendorName] = useState(searchParams.get('vendorName') || '');
  const [ownerName, setOwnerName] = useState('');
  const [customerStatus, setCustomerStatus] = useState('');
  const [customerCurrency, setCustomerCurrency] = useState('');
  const [customerExists, setCustomerExists] = useState(false);
  const [orderExists, setOrderExists] = useState(false);
  const [filterRefetchTime, setFilterRefetchTime] = useState(0);

  const [debounceId, setDebounceId] = useState(null);

  const sessionData = useReactiveVar(sessionVar);
  const resellerLoggedIn = get(
    sessionData,
    'session.user.company.canActAsReseller',
    false
  );

  const { loading: buyerCountLoading } = useQuery(GET_VENDOR_BUYERS_DATA, {
    onCompleted: (data) => {
      setCustomerExists(data.vendorBuyersData.buyersExist);
    },
  });
  const { loading: orderCountLoading } = useQuery(GET_VENDOR_ORDERS_DATA, {
    onCompleted: (data) => {
      setOrderExists(data.vendorOrdersData.ordersExist);
    },
  });

  const { loading: orderListLoading, executer: fetchMoreOrders } =
    useLazyQuery(GET_ORDERS);

  const [sendOrdersCsv, { loading: csvGenerating }] = useMutation(GENERATE_ORDERS_CSV, {
    onCompleted: () => {
      showToast('success', 'Reports have been emailed to you.');
    },
  });

  const loader = useRef(null);

  const fetchMoreOrdersWithFilters = useCallback(
    (variables, concat) => {
      const defaultVariables = {
        first: null,
        after: null,
        number: null,
        company: null,
        status: null,
        decision: null,
        isResellerUser: resellerLoggedIn,
        currency: null,
        ...variables,
      };

      return new Promise((resolve, reject) => {
        setFetchMoreLoading(true);
        if (!buyerCountLoading || !orderCountLoading || !orderListLoading) {
          if (orderExists) {
            if (filterRefetchTime === 0) setFilterRefetchTime(500);
            return fetchMoreOrders(defaultVariables)
              .then((fetchMoreResult) => {
                const newEdges = get(fetchMoreResult, 'data.sellerOrders.edges', []);
                const pageInfo = get(fetchMoreResult, 'data.sellerOrders.pageInfo', {});
                if (pageInfo) {
                  setAfterCursor(pageInfo.endCursor);
                  setHasNextPage(pageInfo.hasNextPage);
                }
                if (concat) {
                  setOrders((prev) => [...prev, ...newEdges]);
                } else {
                  setOrders(newEdges);
                }
                setFetchMoreLoading(false);
                resolve();
              })
              .catch((error) => {
                setFetchMoreLoading(false);
                reject(error);
              });
          }
        }
        return null;
      });
    },
    [
      resellerLoggedIn,
      buyerCountLoading,
      orderCountLoading,
      orderListLoading,
      orderExists,
      filterRefetchTime,
      fetchMoreOrders,
    ]
  );

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        !orderListLoading &&
        !buyerCountLoading &&
        !orderCountLoading &&
        !fetchMoreLoading &&
        hasNextPage &&
        afterCursor
      ) {
        fetchMoreOrdersWithFilters(
          {
            first: 10,
            after: afterCursor,
            number: orderNumber,
            company: companyName,
            ownerName,
            vendorName,
            status: customerStatus,
            currency: customerCurrency,
          },
          true
        );
      }
    },
    [
      orderListLoading,
      buyerCountLoading,
      orderCountLoading,
      fetchMoreLoading,
      hasNextPage,
      afterCursor,
      fetchMoreOrdersWithFilters,
      orderNumber,
      companyName,
      ownerName,
      vendorName,
      customerStatus,
      customerCurrency,
    ]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, loader]);

  useEffect(() => {
    if (
      !companyName &&
      !ownerName &&
      !orderNumber &&
      !vendorName &&
      !customerStatus &&
      !customerCurrency
    ) {
      // filters cleared
      fetchMoreOrdersWithFilters({ first: 10, vendorName }, false);
    } else {
      // filters we set
      setDebounceId(
        setTimeout(
          () =>
            fetchMoreOrdersWithFilters(
              {
                first: 10,
                number: orderNumber,
                vendorName,
                ownerName,
                company: companyName,
                status: customerStatus,
                currency: customerCurrency,
              },
              false
            ),
          filterRefetchTime
        )
      );
    }
  }, [
    companyName,
    ownerName,
    orderNumber,
    vendorName,
    customerStatus,
    fetchMoreOrdersWithFilters,
    filterRefetchTime,
    customerCurrency,
  ]);

  const showCurrency = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const supportedCurrencies = useMemo(() => {
    return get(sessionData, 'session.user.company.product.enabledCurrencies', []);
  }, [sessionData]);
  const currencyFilterOptions = [
    { value: '', label: 'All' },
    ...supportedCurrencies.map((val) => {
      return {
        value: val,
        label: val,
      };
    }),
  ];

  const columns = useMemo(() => {
    const cols = [
      {
        Header: '',
        accessor: 'icon.src',
        Cell: ({ row }) => (
          <div className="pl-2">{isHighlighted(row.original) && <NeedInfoIcon />}</div>
        ),
      },
      {
        Header: 'Order #',
        accessor: 'number',
        Cell: (props) => (
          <Link
            to={`/dashboard/orders/${props.value}/summary`}
            className="vp-text-link-bold text-vartana-blue-60"
          >
            {props.value}
          </Link>
        ),
      },
      {
        Header: 'Customer',
        accessor: 'company.name',
        Cell: (props) => (
          <div className="flex items-start flex-col gap-1">
            <TruncatedText
              variant="paragraph14"
              bold
              color="color-black-100"
              maxChar={30}
              text={titleize(props.row.original.company.name)}
            />
            <Typography variant="paragraph12" color="color-black-100">
              {props.row.original.company.number}
            </Typography>
          </div>
        ),
      },
      {
        Header: 'Amount',
        accessor: 'amount.raw',
        Cell: (props) => {
          const orderAmount = get(props, 'row.original.amount', {});
          return orderAmount.raw ? (
            <Typography variant="paragraph14" color="color-black-100">
              {orderAmount.formatted}
            </Typography>
          ) : (
            TABLE_EMPTY_PLACEHOLDER
          );
        },
      },
      {
        Header: 'Term',
        accessor: 'term.raw',
        Cell: (props) => {
          const orderAmount = get(props, 'row.original.amount', {});
          const term = get(props, 'row.original.term', {});

          const showTerm = orderAmount.raw && term.raw !== 0;
          return showTerm ? (
            <Typography variant="paragraph14" color="color-black-100">
              {term.formatted}
            </Typography>
          ) : (
            TABLE_EMPTY_PLACEHOLDER
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'stage',
        Cell: (props) => (
          <span className={getStatusClass(props.value)}>{startCase(props.value)}</span>
        ),
      },
      {
        Header: 'Valid For',
        accessor: 'validity.raw',
        Cell: (props) =>
          ALLOWED_STATES_TO_EXPIRE.includes(props.row.original.stage) ? (
            <div className="flex items-start flex-col gap-1">
              <Typography variant="paragraph14" color="color-black-100">
                {props.value}
              </Typography>
              <Typography variant="paragraph12" color="color-gray-140">
                {props.row.original.validity.formatted}
              </Typography>
            </div>
          ) : (
            ''
          ),
      },
      {
        Header: 'Owner',
        accessor: 'accountOwner.email',
        Cell: ({ row }) => (
          <>
            {userHasPermission('user', 'view_all', row.original.company) ? (
              <Link
                className="flex relative"
                to="/dashboard/settings/users/view"
                state={{
                  selectedUser: row.original.accountOwner,
                }}
              >
                <TruncatedText
                  variant="paragraph14"
                  bold
                  color="color-blue-120"
                  maxChar={30}
                  text={formatCapital(get(row.original, 'accountOwner.fullName', '--'))}
                />
              </Link>
            ) : (
              <div className="vp-text-link-bold">
                {row.original.accountOwner.fullName}
              </div>
            )}
          </>
        ),
      },
      {
        Header: 'Updated',
        accessor: 'lastUpdated.raw',
        Cell: (props) => (
          <Typography variant="paragraph14" color="color-black-100">
            {props.row.original.lastUpdated.formatted}
          </Typography>
        ),
      },
    ];

    if (showCurrency) {
      cols.splice(3, 0, {
        Header: 'Currency',
        accessor: 'currency',
        Cell: (props) => {
          const currency = get(props, 'row.original.currency', {});
          return <CurrencyPill currency={currency} />;
        },
      });
    }

    if (resellerLoggedIn) {
      cols.splice(3, 0, {
        Header: 'Vendor',
        accessor: 'vendor',
        Cell: ({ value: orderVendor }) => (
          <div className="flex flex-col gap-1">
            <Typography variant="paragraph14" color="color-black-100">
              {orderVendor.name}
            </Typography>
            <Typography variant="paragraph12" color="color-gray-140">
              {orderVendor.number}
            </Typography>
          </div>
        ),
      });
    }

    return cols;
  }, [resellerLoggedIn, showCurrency, userHasPermission]);

  const data = useMemo(() => {
    return orders
      .filter((edge) => edge.node && !isEmpty(edge.node))
      .map((edge) => {
        const order = edge.node;
        return {
          cursor: edge.cursor,
          number: get(order, 'number', ''),
          company: get(order, 'company', {}),
          vendor: get(order, 'company.seller', {}),
          amount: {
            formatted: get(order, 'hideOrderDetailsForCollaborator', false)
              ? ''
              : get(order, 'formattedAmount', ''),
            raw: get(order, 'amount', 0),
          },
          currency: get(order, 'currency', {}),
          term: {
            raw: get(order, 'term', ''),
            formatted:
              get(order, 'term', '') === 0
                ? get(order, 'formattedPaymentTerm', '')
                : get(order, 'formattedTerm', ''),
          },
          stage: get(order, 'vendorState', ''),
          needFundingInvoice: get(order, 'canUploadFundingInvoice', false),
          validity: {
            raw: formatCapital(getTextForNumberOfDays(get(order, 'validForDays'))) || '',
            formatted: get(order, 'validUntil') || '',
          },
          lastUpdated: {
            formatted: getUSDateFormat(get(order, 'updatedAt', '')),
            raw: Date.parse(get(order, 'updatedAt', '')) || '',
          },
          accountOwner: {
            ...get(order, 'user', {}),
            fullName: get(order, 'user.fullName', null),
            email: get(order, 'user.email', ''),
          },
          anyPendingInvoicesOnMilestone: get(
            order,
            'anyPendingInvoicesOnMilestone',
            false
          ),
          milestones: get(order, 'milestones', ''),
        };
      });
  }, [orders]);

  const infiniteLoaders = useCallback(() => {
    let returnVal = null;
    if (fetchMoreLoading) {
      returnVal = <Loader isLoading className="w-12 h-12" containerClassName="" />;
    } else if (!fetchMoreLoading && hasNextPage) {
      returnVal = <div ref={loader} />;
    }
    return returnVal;
  }, [fetchMoreLoading, hasNextPage]);

  const onboardingSteps = get(vendor, 'seller.onboardingSteps', []);
  const importStep = onboardingSteps.find((step) => step.name === 'import');
  const showZeroState =
    get(importStep, 'status', 'complete') === 'complete' &&
    !orderExists &&
    !orderCountLoading;

  const noDataState =
    get(importStep, 'status', 'complete') === 'complete' &&
    orderExists &&
    data.length === 0;

  const mainBody = useMemo(() => {
    if (data.length) {
      return (
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-ml-6 lg:-ml-8">
            <div className="py-2 align-middle inline-block min-w-full sm:pl-6 lg:pl-8">
              <div className="overflow-hidden border-b border-gray-200 sm:rounded-none">
                <Table
                  columns={columns}
                  data={data}
                  getHeaderProps={() => ({
                    className:
                      'py-4 px-4 text-left text-xs font-medium text-vartana-gray-60 whitespace-nowrap tracking-wider uppercase',
                  })}
                  getCustomRowProps={(row) => ({
                    className: isHighlighted(row.original) ? 'bg-vartana-yellow-20' : '',
                  })}
                />
                {infiniteLoaders()}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (showZeroState) {
      return (
        <ZeroState page="orders" cta={customerExists ? 'add_order' : 'add_customer'} />
      );
    }

    if (noDataState) {
      return <NoDataState />;
    }

    return (
      <div className="flex justify-center">
        <div className="absolute text-center top-2/4 transform-gpu -translate-y-2/4 space-y-4">
          <div className="inline-block">
            <img alt="generating-plans" src={generatingPlansIcon} />
          </div>
          <div className="">
            <Typography variant="heading24" color="color-gray-100">
              Hang on tight!
            </Typography>
            <br></br>
            <Typography variant="heading14" color="color-gray-110">
              We’re generating payment options for your customers.
            </Typography>
            <br></br>
            <Typography variant="heading14" color="color-gray-110">
              Visit &quot;
              <Link to="/dashboard/customers">
                <Typography variant="paragraph14" bold color="color-blue-120">
                  Customers
                </Typography>
              </Link>
              &quot; to see decisioning status.
            </Typography>
          </div>
        </div>
      </div>
    );
  }, [data, showZeroState, noDataState, columns, infiniteLoaders, customerExists]);

  return (
    <>
      {orderListLoading || buyerCountLoading || orderCountLoading ? (
        <Loader
          isLoading={orderListLoading || buyerCountLoading || orderCountLoading}
          containerClassName="h-full flex"
        />
      ) : (
        <>
          <div className="flex flex-row px-8 border-b border-gray-200 bg-white justify-between items-center">
            <div className="flex flex-col justify-center h-[6.375rem]">
              <Typography variant="heading24" color="color-black-100">
                Orders
              </Typography>
            </div>
            {!showZeroState && (
              <div className="space-x-4">
                <HasPermission resource={RESOURCE.order} action={ACTION.create}>
                  <Button onClick={() => navigate('/dashboard/orders/new')}>
                    Create order
                  </Button>
                </HasPermission>
              </div>
            )}
          </div>
          {!showZeroState && (
            <div className="border-b border-vartana-gray-40-v3 bg-white">
              <div className="pt-4 pb-6 px-8">
                <div className="filter-container-responsive">
                  <div className="filter-responsive">
                    <InputField
                      label="Order #"
                      name="order-number"
                      placeholder="O-009DEF23"
                      suffixIcon={<SearchIcon />}
                      fullWidth
                      onChange={(e) => {
                        clearTimeout(debounceId);
                        setOrderNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="filter-responsive">
                    <InputField
                      label="Customer"
                      name="customer-name"
                      suffixIcon={<SearchIcon />}
                      fullWidth
                      onChange={(e) => {
                        clearTimeout(debounceId);
                        setCompanyName(e.target.value);
                      }}
                    />
                  </div>
                  {resellerLoggedIn && (
                    <div className="filter-responsive">
                      <InputField
                        label="Vendor"
                        name="vendor-number"
                        fullWidth
                        suffixIcon={<SearchIcon />}
                        value={vendorName}
                        onChange={(e) => {
                          clearTimeout(debounceId);
                          setVendorName(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  <div className="filter-responsive">
                    <InputField
                      label="Owner"
                      name="owner-name"
                      suffixIcon={<SearchIcon />}
                      fullWidth
                      onChange={(e) => {
                        clearTimeout(debounceId);
                        setOwnerName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="filter-responsive">
                    <DropdownInput
                      label="Status"
                      name="order-status"
                      options={OrderStatusOptions}
                      value={customerStatus}
                      isSearchable={false}
                      onChange={({ value }) => {
                        clearTimeout(debounceId);
                        setCustomerStatus(value);
                      }}
                    />
                  </div>
                  {showCurrency && (
                    <div className="filter-responsive">
                      <DropdownInput
                        label="Currency"
                        name="currency"
                        options={currencyFilterOptions}
                        value={customerCurrency}
                        isSearchable={false}
                        onChange={({ value }) => {
                          clearTimeout(debounceId);
                          setCustomerCurrency(value);
                        }}
                      />
                    </div>
                  )}

                  {showCsvExport ? (
                    <div className="flex items-end">
                      <Button
                        size="x-small"
                        variant="outlined"
                        disabled={csvGenerating || orders?.length === 0}
                        onClick={() =>
                          sendOrdersCsv({
                            variables: {
                              number: orderNumber,
                              company: companyName,
                              vendorName,
                              status: customerStatus,
                            }})}
                      >
                        {csvGenerating ? (
                          <Loader className="w-5 h-5 border-l-white border-r-white" />
                        ) : (
                          'Export CSV'
                        )}
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {mainBody}
        </>
      )}
    </>
  );
}
