import * as yup from 'yup';
import {
  commonRegex,
  MIN_PASSWORD_LENGTH,
  PASSWORD_ERRORS,
} from '../constants/common.constants';

// Validates that the password field meets specific criteria:
// - Required, minimum length of 8 characters
// - Contains at least one numeric digit
// - Contains at least one uppercase and one lowercase letter
export const passwordFieldValidation = yup
  .string()
  .required('This field is required')
  .test((value, { createError }) => {
    const errors = [];

    if (value?.length < MIN_PASSWORD_LENGTH) {
      errors.push(PASSWORD_ERRORS.lengthError);
    }
    if (!commonRegex.atLeastOneNumber.test(value)) {
      errors.push(PASSWORD_ERRORS.numberError);
    }
    if (
      !(
        commonRegex.atLeastOneSmallAlphabet.test(value) &&
        commonRegex.atLeastOneCapitalAlphabet.test(value)
      )
    ) {
      errors.push(PASSWORD_ERRORS.casingError);
    }

    if (errors.length === 0) {
      return true;
    }

    return createError({
      message: {
        errors,
      },
    });
  });

/**
 * Generates an address validator schema.
 * @param {boolean} isOptional - If true, all fields will be optional (nullable).
 * @returns {yup.ObjectSchema} - The address validator schema.
 */

export const createAddressValidator = (isOptional = false) => {
  const baseSchema = {
    state: yup.string(),
    city: yup.string().max(50, 'Too Long!'),
    zip: yup.number(),
    street: yup.string().max(50, 'Too Long!'),
    suite: yup.string().max(50, 'Too Long!'),
  };

  // If the fields should be optional, add .nullable() to each field
  if (isOptional) {
    Object.keys(baseSchema).forEach((key) => {
      baseSchema[key] = baseSchema[key].nullable();
    });
  }

  return baseSchema; // Return a plain object, not a Yup schema
};
