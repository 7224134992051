import { Button, CompactInputField, Typography } from '@vartanainc/design-system';
import {
  Ref,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { DebouncedFunc, get, startCase } from 'lodash';
import _debounce from 'lodash/debounce';
import { FormikProvider, useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import InterestRateSelect from '../../../../components/InterestRateSelect/InterestRateSelect';
import { removeTrailingZeros } from '../../../../utils/helpers';
import TermsDropdown from '../../../../designSystem/CompactFields/TermsDropdown';
import { CALCULATE_PROPOSED_PAYMENTS } from '../../../../graphql/queries/progen';
import { WidgetMetaContext } from '../../../../context/WidgetMetaContext/WidgetMetaContext';
import CompactNumericField from '../../../../designSystem/CompactFields/CompactNumericField';
import SubsidyField from '../../../../designSystem/CompactFields/SubsidyField';
import CompactDropdownInput from '../../../../designSystem/CompactFields/CompactDropDown';
import { WidgetContext } from '../../../../context/WidgetContext';
import {
  oneWholeTwoDecimal,
  SPIFF_MODE,
  WIDGET_WIDTH_VARIANTS,
} from '../../../../constants/common.constants';
import { MAX_ALLOWED_TCV } from '../../../Orders/order.constants';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { PAYOUT_LABELS } from '../../../CommonWidgetV2/commonWidget.constants';
import {
  MAX_OPTIONS_COUNT_FOR_INTEREST_RATE_RADIO_MODE,
  WidgetWidthVariantType,
} from '../widgetV2.constants';
import { computeClosestTerm, getBackfilledSubsidy } from '../../../Orders/order.utils';
import { DropdownOption } from '../../../../utils/commonInterfaces';
import { getMaxInterestRateDropdownOptions } from '../widgetUtils';

interface ErrorProps {
  message: string;
  subsidy: string;
}

interface CalculatorProps {
  onValueChange: (value: unknown) => void;
  setLoading: (value: boolean) => void;
  isInstallment?: boolean;
  calculatorRef: Ref<unknown>;
  setCanDownloadProposal: (canDownloadProposal: boolean) => void;
  onFrequencyChange?: (value) => void;
  setError?: (value) => void;
  error?: ErrorProps;
}

interface ProductProps {
  defaultPaymentTerm?: number;
  defaultPaymentFrequency?: string;
  defaultContractLength?: number;
  defaultSpiffRate?: number;
}

interface calculatorPayloadProps {
  current?: {
    [key: string]: string;
  };
}

interface DropdownOptions {
  value: string;
  label: string;
}

const Calculator = ({
  onValueChange,
  setLoading,
  isInstallment,
  calculatorRef,
  setCanDownloadProposal,
  onFrequencyChange,
  setError,
  error,
}: CalculatorProps): JSX.Element => {
  const widgetData = useContext(WidgetMetaContext);
  const widgetInterestRateOptions: DropdownOption[] = get(
    widgetData,
    'meta.calculator.buyerInterestRates',
    []
  );
  let { companyNumber } = useParams();
  const widgetContext = useContext(WidgetContext);
  const product = get(
    widgetData,
    'meta.calculator.defaultProductDetails',
    {}
  ) as ProductProps;

  if (!companyNumber) companyNumber = get(widgetContext, 'selectedCompany.number', '');
  const [maxSubsidy, setMaxSubsidy] = useState<string | null>('');
  const [formattedInterestRateOptions, setFormattedInterestRateOptions] = useState<
    DropdownOption[]
  >(widgetInterestRateOptions);
  const [availableCredit, setAvailableCredit] = useState('');
  const [tcvWarning, setTcvWarning] = useState(false);
  const [termWarning, setTermWarning] = useState(false);
  const [spiffWarning, setSpiffWarning] = useState(null);
  const [subsidyWarning, setSubsidyWarning] = useState(null);
  const [isCreditTruncated, setIsCreditTruncated] = useState(false);
  const [isSubsidyTruncated, setIsSubsidyTruncated] = useState(false);
  const [closeTermDropdown, setCloseTermDropdown] = useState(false);
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const [filteredBillingFrequencies, setFilteredBillingFrequencies] = useState<
    DropdownOptions[]
  >([]);
  const [filteredNetTerms, setFilteredNetTerms] = useState<DropdownOptions[]>([]);
  const [backfilledSubsidy, setBackfilledSubsidy] = useState<string>('');
  const [shouldBackfillSubsidy, setShouldBackfillSubsidy] = useState<boolean>(false);

  const widthVariant = get(widgetContext, 'widthVariant', '') as WidgetWidthVariantType;
  const isCustomSchedule = get(widgetData, 'meta.isCustomSchedule', false);
  const spiffMode = useMemo(() => {
    return get(widgetData, 'meta.calculator.spiffMode');
  }, [widgetData]);
  const calculatorPayloadRef = useRef() as calculatorPayloadProps;
  const installmentApprovedCreditTerms = get(
    widgetData,
    'meta.calculator.installmentApprovedCreditTerms',
    []
  );
  const interestRateDropdownModeWidthVariants = Object.keys(WIDGET_WIDTH_VARIANTS).filter(
    (variant) => ![WIDGET_WIDTH_VARIANTS.xl, WIDGET_WIDTH_VARIANTS.sm].includes(variant)
  );
  const isSubsidyAllowed = get(widgetData, 'meta.calculator.showSubsidyField', true);
  const isSpiffAllowed = spiffMode !== SPIFF_MODE.none;

  const shouldShowInterestRate = useMemo(() => {
    return get(widgetData, 'meta.calculator.applyBuyerInterestRate', false);
  }, [widgetData]);

  // adding xl fallback for VD case where widthvariant is undefined
  const maxCountForRadioMode =
    MAX_OPTIONS_COUNT_FOR_INTEREST_RATE_RADIO_MODE[
      widthVariant || WIDGET_WIDTH_VARIANTS.xl
    ];

  const shouldShowInterestRateDropdownMode =
    formattedInterestRateOptions.length > maxCountForRadioMode ||
    interestRateDropdownModeWidthVariants.includes(widthVariant) ||
    isCustomSchedule;

  const shouldOpenInterestRateDropdownBelow = useMemo(() => {
    return (
      getMaxInterestRateDropdownOptions(
        widthVariant,
        !!isInstallment,
        isSubsidyAllowed && isSpiffAllowed
      ) >= formattedInterestRateOptions.length
    );
  }, [
    formattedInterestRateOptions.length,
    isInstallment,
    isSpiffAllowed,
    isSubsidyAllowed,
    widthVariant,
  ]);

  // Handles Term option array for Custom Schedule and other cases
  const termOptions = useMemo(() => {
    const calculatorTerms = Object.keys(installmentApprovedCreditTerms);

    // filtering duplicate terms in case of prorated terms
    const mappedTerms = calculatorTerms
      .filter((term, index) => calculatorTerms.indexOf(term) === index)
      .map((term) => ({
        value: parseInt(term, 10),
        label: String(term).includes('co-term')
          ? `${String(term).replace('(co-term)', 'months (co-term)')}`
          : `${String(term)} months`,
      }));

    // Sorting by value to ensure order
    return mappedTerms.sort((a, b) => a.value - b.value);
  }, [installmentApprovedCreditTerms]);

  const isSyndicatedOrder = useMemo(() => {
    return get(widgetData, 'meta.calculator.isSyndicated');
  }, [widgetData]);

  // Disabling Terms Drop down if termOptions array has 1 or 0 elements and is either syndicated or custom schedule
  const disableTermsDropdown = useMemo(() => {
    return termOptions.length < 2 && (isSyndicatedOrder || isCustomSchedule);
  }, [isCustomSchedule, isSyndicatedOrder, termOptions]);

  const currencySymbol = useMemo(() => {
    return get(widgetData, 'meta.calculator.currencySymbol');
  }, [widgetData]) as unknown as string;

  const [getProposalPayments] = useLazyQuery(CALCULATE_PROPOSED_PAYMENTS);

  const getInitialTerm = useCallback(() => {
    // this returns the default contract term if it exists in the approved terms
    return product.defaultContractLength &&
      termOptions.find((option) => option.value === product.defaultContractLength)
      ? product.defaultContractLength
      : termOptions[0]?.value;
  }, [product.defaultContractLength, termOptions]);

  const showSubsidyInPercentage = useCallback(() => {
    return get(widgetData, 'meta.calculator.showSubsidyInPercentage', false);
  }, [widgetData]);
  const getInitialFrequency = useCallback(() => {
    const term = getInitialTerm();
    let frequencyList = filteredBillingFrequencies;
    if (!frequencyList.length) {
      const termData = installmentApprovedCreditTerms[term];
      frequencyList = termData
        ? Object.keys(termData)
            .filter((frequency) => !(term === 12 && frequency === 'annual')) // Skip 'annual' for term 12
            .map((frequency) => ({
              value: frequency,
              label: startCase(frequency),
            }))
        : [];
    }
    // this returns the default frequency if it exists in the approved frequencies
    return product.defaultPaymentFrequency &&
      frequencyList.find((option) => option.value === product.defaultPaymentFrequency)
      ? product.defaultPaymentFrequency
      : frequencyList[0]?.value;
    // We want this to update this only when product.defaultPaymentFrequency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.defaultPaymentFrequency, filteredBillingFrequencies]);

  const getInitialNetTerm = useCallback(() => {
    // this returns the default net-term if it exists in the approved net-terms
    let netTermsList = filteredNetTerms;

    if (isInstallment) {
      const term = getInitialTerm();
      const frequency = getInitialFrequency();
      if (!netTermsList.length) {
        const termData = installmentApprovedCreditTerms[term];
        const frequencyData = termData && termData[frequency];

        netTermsList = frequencyData
          ? Object.values(frequencyData).map((netTerm) => ({
              value: netTerm as unknown as string,
              label: netTerm === 0 ? 'Upon receipt' : `Net-${netTerm}`,
            }))
          : [];
      }
    }
    return product.defaultPaymentTerm &&
      netTermsList.find(
        (option) => option.value === (product.defaultPaymentTerm as unknown as string)
      )
      ? product.defaultPaymentTerm
      : netTermsList[0]?.value;
  }, [
    filteredNetTerms,
    isInstallment,
    product.defaultPaymentTerm,
    getInitialTerm,
    getInitialFrequency,
    installmentApprovedCreditTerms,
  ]);

  const spiffDefaultValue = product.defaultSpiffRate || 0;

  const initialValues = useMemo(() => {
    return {
      totalContractValue: '',
      term: isInstallment && getInitialTerm(),
      netTerm: getInitialNetTerm(),
      billingFrequency: isInstallment ? getInitialFrequency() : undefined,
      subsidy: undefined,
      paymentType: isInstallment ? 'installments' : 'net_terms',
      isDollar: !showSubsidyInPercentage(),
      spiffRate: spiffMode === 'fixed' ? spiffDefaultValue * 100 : '',
      applyOfferedBuyerInterestRate: false,
    };
    // want the initial values to run only if one of the dependencies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstallment, spiffDefaultValue, spiffMode]);

  const handleCalculatorState = useCallback(
    (response, values): void => {
      calculatorPayloadRef.current = response;
      let subsidy = values.isDollar
        ? response?.dollar_max_subsidy
        : response?.max_subsidy_percentage;
      if (!values.isDollar && !response?.max_subsidy) subsidy = '';
      const recalculatedInterestRateOptions = get(response, 'buyer_interest_rates', []);
      setFormattedInterestRateOptions(recalculatedInterestRateOptions);
      const appliedSubsidy = get(response, 'applied_subsidy', {});
      const newBackfilledSubsidy = getBackfilledSubsidy(appliedSubsidy, values.isDollar);
      const subsidyHasError = !!get(response, 'errors.subsidy', false);
      if (!subsidyHasError) {
        setBackfilledSubsidy(newBackfilledSubsidy);
        setShouldBackfillSubsidy(true);
      }

      setMaxSubsidy(
        // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
        response?.dollar_max_subsidy === '$0.00' ? null : removeTrailingZeros(subsidy)
      );
      setTcvWarning(
        !!(get(response, 'errors.amount') || get(response, 'errors.message'))
      );
      setSubsidyWarning(get(response, 'errors.subsidy'));
      setTermWarning(get(response, 'errors.term'));
      setSpiffWarning(get(response, 'errors.spiff', ''));
      setAvailableCredit(get(response, 'available_credit'));
      const isEllipsis = document.getElementsByClassName('ellipsis');
      setIsCreditTruncated(isEllipsis[0]?.clientWidth / 16 > 6.95);
      setIsSubsidyTruncated(isEllipsis[1]?.clientWidth / 16 > 6.75);
      onValueChange(response);
    },
    [onValueChange]
  );

  const calculatePayments = useCallback(
    async (values) => {
      if (!values.totalContractValue || Number(values.totalContractValue) === 0) return;
      setLoading(true);
      setCloseTermDropdown(true);
      const subsidy = get(widgetData, 'meta.calculator.showSubsidyField', false)
        ? values.subsidy
        : null;
      const applyOfferedBuyerInterestRate =
        !!values?.interestRate && values?.interestRate !== 'null';

      const dollarBlindDiscount =
        values.isDollar && !applyOfferedBuyerInterestRate ? parseFloat(subsidy) : 0;
      const percentageBlindDiscount =
        values.isDollar || applyOfferedBuyerInterestRate ? 0 : parseFloat(subsidy);

      const { data } = await getProposalPayments({
        variables: {
          paymentType: values.paymentType,
          billingFrequency: values.billingFrequency,
          term: values.term,
          paymentTerm: parseInt(values.netTerm, 10),
          dollarBlindDiscount,
          percentageBlindDiscount,
          amount: parseFloat(values.totalContractValue) || 0,
          companyNumber,
          isDollar: values.isDollar,
          spiffRate: parseFloat(values.spiffRate) || 0,
          offeredBuyerInterestRate: values.interestRate
            ? parseFloat(values?.interestRate)
            : 0,
          applyOfferedBuyerInterestRate,
        },
      });
      setLoading(false);
      setCloseTermDropdown(false);
      handleCalculatorState(data?.calculateProposedPayments, values);
    },
    [companyNumber, getProposalPayments, handleCalculatorState, setLoading, widgetData]
  );

  const handleSubmit = (values): void => {
    calculatePayments(values);
    setCanDownloadProposal(values.totalContractValue > 0);
  };

  const Options = useMemo(
    () => [
      {
        label: '%',
        value: '%',
        default: showSubsidyInPercentage(),
      },
      {
        label: currencySymbol,
        value: '$',
        default: !showSubsidyInPercentage(),
      },
    ],
    [currencySymbol, showSubsidyInPercentage]
  );

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (shouldBackfillSubsidy) {
      formikBag.setFieldValue('subsidy', backfilledSubsidy);
      setShouldBackfillSubsidy(false);
    }
  }, [backfilledSubsidy, formikBag, shouldBackfillSubsidy]);

  // calls onFrequencyChange whenever the frequency is updated in formik
  useEffect(() => {
    if (onFrequencyChange)
      onFrequencyChange({ value: formikBag.values.billingFrequency });
  }, [formikBag.values.billingFrequency, onFrequencyChange]);

  const debouncedSubmitRef = useRef<DebouncedFunc<() => Promise<() => void>>>(
    _debounce(() => formikBag.submitForm(), 1000)
  );
  const tcvFieldRef = useRef<HTMLInputElement>(null);
  const spiffFieldRef = useRef<HTMLInputElement>(null);

  const debouncedSubmit = useCallback((): void => {
    if (!isCustomSchedule && debouncedSubmitRef.current) {
      debouncedSubmitRef.current();
    }
  }, [isCustomSchedule]);

  useEffect(() => {
    if (setError) {
      setError({ ...error, term: formikBag.errors.term });
    }
    // adding this because having error in the dependency array causes infinite re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.errors.term, setError]);

  const validateTcv = (): void => {
    if (!formikBag.values.totalContractValue) {
      setTcvWarning(true);
      formikBag.setFieldTouched('totalContractValue', true);
      tcvFieldRef.current?.focus();
    }
  };

  useImperativeHandle(calculatorRef, () => ({
    formRef: formikBag,
    validateTcv,
  }));

  const handleSubsidyApply = (): void => {
    formikBag
      .setFieldValue('subsidy', maxSubsidy?.replace(/[%, $]/g, ''))
      .then(() => debouncedSubmit());
  };

  const generateMaxSubsidy = (isDollar): string => {
    if (isDollar) {
      return get(
        widgetData,
        'meta.calculator.proposedPayments.max_subsidy_percentage',
        '0'
      );
    }

    return get(widgetData, 'meta.calculator.proposedPayments.dollar_max_subsidy', '0');
  };

  const handleSubsidyUnitChange = async (): Promise<void> => {
    let subsidy = parseFloat(formikBag.values.subsidy || '0');
    const { isDollar, totalContractValue: totalContractFormValue } = formikBag.values;
    const totalContractValue = parseFloat(totalContractFormValue || '0');
    const {
      dollar_max_subsidy: dollarMaxSubsidy,
      max_subsidy_percentage: percentageMaxSubsidy,
    } = calculatorPayloadRef.current || {};
    const convertedMaxSubsidy = isDollar
      ? percentageMaxSubsidy || ''
      : dollarMaxSubsidy || '';

    if (totalContractValue) {
      // if converting to percentage then convert subsidy value to percentage as well
      if (isDollar)
        subsidy = parseFloat(((subsidy / totalContractValue) * 100).toFixed(2));
      // if converting to value then convert subsidy from percentage back to value
      else subsidy = parseFloat(((subsidy / 100) * totalContractValue).toFixed(2));
    }

    if (subsidy.toString().includes('.00')) subsidy = Math.round(subsidy);
    if (formikBag.values.subsidy !== '')
      await formikBag.setFieldValue('subsidy', `${subsidy}`);
    setMaxSubsidy(
      // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
      convertedMaxSubsidy === '$0.00' || convertedMaxSubsidy === '0.0%'
        ? ''
        : removeTrailingZeros(convertedMaxSubsidy)
    );

    if (isCustomSchedule) {
      setMaxSubsidy(generateMaxSubsidy(isDollar));
    }
    await formikBag.setFieldValue('isDollar', !formikBag.values.isDollar);
    debouncedSubmit();
  };

  const getSubsidyClass = (): string => {
    const inputElement: HTMLInputElement | null =
      document.querySelector('.subsidy-input input');
    if (inputElement && !inputElement.value) return 'subsidy-input empty-subsidy';
    return 'subsidy-input';
  };

  // Function to get the keys of the Term nested Frequencies object
  const filterBillingFrequencies = (term: number): DropdownOptions[] | [] => {
    const closesTerm = computeClosestTerm(term, installmentApprovedCreditTerms);
    const nestedData =
      installmentApprovedCreditTerms[closesTerm] ||
      installmentApprovedCreditTerms[`${closesTerm} (co-term)`];
    if (!nestedData) {
      return [];
    }
    return Object.keys(nestedData)
      .filter((frequency) => !(term === 12 && frequency === 'annual')) // Skip 'annual' for term 12
      .map((frequency) => ({
        value: frequency,
        label: startCase(frequency),
      }));
  };

  // Function to get the keys of the Term and Frequency nested Net Terms object
  const filterNetTerms = (term: number, frequency: string): DropdownOptions[] | [] => {
    const closesTerm = computeClosestTerm(term, installmentApprovedCreditTerms);
    const nestedData =
      installmentApprovedCreditTerms[closesTerm] ||
      installmentApprovedCreditTerms[`${closesTerm} (co-term)`];
    if (!nestedData || !nestedData[frequency]) {
      return [];
    }
    // Need conversion as netTerm itself is a number but DropDownOptions interface
    // takes option and value as string
    return Object.values(nestedData[frequency]).map((netTerm) => ({
      value: netTerm as unknown as string,
      label: netTerm === 0 ? 'Upon receipt' : `Net-${netTerm}`,
    }));
  };

  const shouldShowMaxSubsidy =
    get(widgetData, 'meta.calculator.showSubsidyField', false) && !shouldShowInterestRate;

  const defaultInterestRate = useMemo(() => {
    if (isCustomSchedule && formattedInterestRateOptions) {
      const defaultValue = get(
        widgetData,
        'meta.calculator.proposedPayments.buyer_interest_rate'
      );
      return formattedInterestRateOptions?.find(
        (option) => option.label === defaultValue
      );
    }
    return undefined;
  }, [formattedInterestRateOptions, isCustomSchedule, widgetData]);

  const shouldHideInterestRateForCustomSchedule =
    shouldShowInterestRate && isCustomSchedule && !defaultInterestRate;

  const onSubsidyKeyPress = useCallback(
    async (e): Promise<void> => {
      if (shouldShowInterestRate && get(formikBag.values, 'interestRate')) {
        await formikBag.setFieldValue('interestRate', null);
      }
      debouncedSubmit();
    },
    [shouldShowInterestRate, formikBag, debouncedSubmit]
  );

  const onSpiffValueChange = useCallback(
    async (e): Promise<void> => {
      await formikBag.setFieldValue('spiffRate', e.value);
      debouncedSubmit();
    },
    [formikBag, debouncedSubmit]
  );

  const onInterestRateValueChange = useCallback(
    (option): void => {
      formikBag.setFieldValue('subsidy', null);
      debouncedSubmit();
    },
    [formikBag, debouncedSubmit]
  );
  /*
    Triggers when filteredBillingFrequencies is updated
    if current selected billing frequency is not found in filteredBillingFrequencies
    then sets the 0th index value as billing frequency
    Updates the filteredNetTerms based on the term and billing frequency
  */
  useEffect(() => {
    if (filteredBillingFrequencies.length > 0) {
      const foundObject = filteredBillingFrequencies.find(
        (frequency) => frequency.value === formikBag.values.billingFrequency
      );
      if (!foundObject) {
        formikBag.setFieldValue('billingFrequency', filteredBillingFrequencies[0].value);
      } else if (formikBag.values.term && formikBag.values.billingFrequency) {
        setFilteredNetTerms(
          filterNetTerms(formikBag.values.term, formikBag.values.billingFrequency)
        );
      }
    }
    // adding this to ensure re render only if filteredBillingFrequencies changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredBillingFrequencies]);

  /*
    Triggers when filteredNetTerms is updated
    if current selected net term is not found in filteredNetTerms
    then sets the 0th index value as net term
  */
  useEffect(() => {
    if (filteredNetTerms.length > 0) {
      const foundObject = filteredNetTerms.find(
        (netTerm) => netTerm.value === formikBag.values.netTerm
      );
      if (!foundObject) {
        formikBag.setFieldValue('netTerm', filteredNetTerms[0].value);
      }
    }
    // adding this to ensure re render only if filteredNetTerms changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredNetTerms]);

  /*
    Triggers when term drop down value is updated
    Filters out Biling frequencies based on the selected term
  */
  useEffect(() => {
    if (
      (formikBag.values.term || formikBag.values.term === 0) &&
      !formikBag.errors.term
    ) {
      setFilteredBillingFrequencies(filterBillingFrequencies(formikBag.values.term));
      debouncedSubmit();
    }
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.values.term, formikBag.errors.term]);

  useEffect(() => {
    if (termOptions.length > 0 && isInstallment) {
      const updatedValidationSchema = validationSchema.clone().shape({
        term: Yup.number().required('Contract length is required'),
      });
      setValidationSchema(updatedValidationSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termOptions, isInstallment]);

  // Update Net terms based on Billing Frequency selected
  useEffect(() => {
    if (formikBag.values.billingFrequency && formikBag.values.term && !isCustomSchedule) {
      setFilteredNetTerms(
        filterNetTerms(formikBag.values.term, formikBag.values.billingFrequency)
      );
    }
    // We want net terms to update only when formikBag.values.billingFrequency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.values.billingFrequency]);

  const maxSubsidyLabel = useMemo(() => {
    if (widthVariant !== WIDGET_WIDTH_VARIANTS.xl) return PAYOUT_LABELS.maxSubsidy.small;
    return PAYOUT_LABELS.maxSubsidy.default;
  }, [widthVariant]);

  const shouldDisableInterestRateSelect =
    !formikBag.values?.totalContractValue || (isCustomSchedule && !defaultInterestRate);

  const availableAmountLabel = useMemo(() => {
    if (shouldShowInterestRate) {
      if (availableCredit?.length > 9 && !shouldShowInterestRateDropdownMode) {
        return PAYOUT_LABELS.availableAmount.small;
      }
      return PAYOUT_LABELS.availableAmount.medium;
    }
    if (widthVariant && widthVariant !== WIDGET_WIDTH_VARIANTS.xl)
      return PAYOUT_LABELS.availableAmount.small;
    return PAYOUT_LABELS.availableAmount.default;
  }, [
    availableCredit?.length,
    shouldShowInterestRate,
    shouldShowInterestRateDropdownMode,
    widthVariant,
  ]);

  const shouldRenderInterestRate = useMemo(() => {
    return (
      shouldShowInterestRate &&
      !shouldHideInterestRateForCustomSchedule &&
      formattedInterestRateOptions?.length > 0
    );
  }, [
    shouldShowInterestRate,
    shouldHideInterestRateForCustomSchedule,
    formattedInterestRateOptions,
  ]);

  useEffect(() => {
    // Pre populate the form with the values from the widgetData and disabling the form for Custom Schedule
    if (isCustomSchedule) {
      const amount = get(widgetData, 'meta.calculator.proposedPayments.amount', 0);
      const subsidyInPercentage = get(widgetData, 'meta.calculator.subsidy', 0);
      const spiffValue = get(
        widgetData,
        'meta.calculator.proposedPayments.spiff_rate',
        '0'
      );
      const customScheduleAvailableCredit = get(
        widgetData,
        'meta.calculator.proposedPayments.available_credit',
        '0'
      );
      const customScheduleMaxSubsidy = get(
        widgetData,
        'meta.calculator.proposedPayments.max_subsidy_percentage',
        '0'
      );
      formikBag.setFieldValue('totalContractValue', amount);
      formikBag.setFieldValue('subsidy', subsidyInPercentage);
      formikBag.setFieldValue('spiffRate', spiffValue);
      setAvailableCredit(customScheduleAvailableCredit);
      setMaxSubsidy(customScheduleMaxSubsidy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomSchedule]);

  // Setting up Net Terms Drop down for Defer tab
  useEffect(() => {
    if (!isInstallment) {
      const netTerms = get(widgetData, 'meta.calculator.deferApprovedNetTerms', []).map(
        (netTerm) => ({
          value: String(netTerm),
          label: netTerm === 0 ? 'Upon receipt' : `Net-${netTerm}`,
        })
      );
      setFilteredNetTerms(netTerms);
    }
    // Want this to run once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikProvider value={formikBag}>
      <form onSubmit={calculatePayments}>
        <div className="flex flex-col gap-2.5 calculator-container">
          <div className="flex flex-col gap-1.5 fields-parent-container">
            <div className="flex gap-x-1.5 fields-upper-container">
              <div className="flex-2 tcv-field">
                <CompactNumericField
                  label="Total contract value"
                  customPrefix={currencySymbol}
                  placeholder="0"
                  name="totalContractValue"
                  showError={!!tcvWarning}
                  showHoverShadow={false}
                  maxAllowedValue={MAX_ALLOWED_TCV}
                  onValueChange={(e) => {
                    formikBag
                      .setFieldValue('totalContractValue', e.value)
                      .then(() => debouncedSubmit());
                  }}
                  decimalScale={2}
                  ref={tcvFieldRef}
                  disabled={isCustomSchedule}
                />
              </div>

              {get(widgetData, 'meta.calculator.showSubsidyField', true) && (
                <div className="flex-2 subsidy-field">
                  <SubsidyField
                    className={`${getSubsidyClass()}`}
                    label="Subsidy"
                    options={Options}
                    defaultSelectValue={Options.find((obj) => obj.default)}
                    name="subsidy"
                    customPrefix={formikBag.values.isDollar ? currencySymbol : ''}
                    placeholder="0"
                    showError={!!subsidyWarning}
                    onBlur={() => debouncedSubmit()}
                    onUnitChange={(e) => {
                      handleSubsidyUnitChange();
                    }}
                    onKeyDown={onSubsidyKeyPress}
                    value={formikBag.values.subsidy || undefined}
                    decimalScale={2}
                    fixedDecimalScale={false}
                    disabled={isCustomSchedule}
                    selectEnabled
                  />
                </div>
              )}

              {spiffMode !== 'none' && (
                <div className={`flex-1 ${spiffWarning ? 'is-error' : ''} spiff-field`}>
                  <CompactNumericField
                    label="SPIFF"
                    className="flex-1"
                    placeholder="0%"
                    customSuffix="%"
                    name="spiffRate"
                    maxAllowedValue={oneWholeTwoDecimal}
                    showHoverShadow={false}
                    showError={!!spiffWarning}
                    disabled={spiffMode === SPIFF_MODE.fixed || isCustomSchedule}
                    onValueChange={onSpiffValueChange}
                    decimalScale={2}
                    ref={spiffFieldRef}
                  />
                </div>
              )}
            </div>
            <div className="flex gap-x-1.5 fields-lower-container">
              {isInstallment && (
                <>
                  <TermsDropdown
                    name="term"
                    disabled={disableTermsDropdown}
                    label="Contract length"
                    id="term"
                    options={termOptions}
                    showError={!!termWarning || !!formikBag.errors.term}
                    isCompact
                    closeDropdown={closeTermDropdown}
                    handleCloseDropdown={setCloseTermDropdown}
                    resetOptionsOnBlur
                    dropdownOnly={isSyndicatedOrder}
                  />
                  <CompactDropdownInput
                    label="Frequency"
                    name="billingFrequency"
                    options={filteredBillingFrequencies}
                    onChange={() => {
                      formikBag.submitForm();
                    }}
                    disabled={filteredBillingFrequencies.length < 2}
                    showHoverShadow={false}
                  />
                </>
              )}
              {!isCustomSchedule ? (
                <CompactDropdownInput
                  name="netTerm"
                  label="Net terms"
                  options={filteredNetTerms}
                  onChange={() => formikBag.submitForm()}
                  disabled={filteredNetTerms.length < 2}
                  showHoverShadow={false}
                />
              ) : (
                /* We've used conditional rendering to render custom fields without effecting the existing ones */
                <CompactInputField label="Net terms" value="Custom" disabled />
              )}
            </div>
          </div>
          <div className="calculator-payout-container flex items-center justify-between self-stretch gap-4">
            <div className="flex justify-center items-center gap-[0.24rem]">
              <Typography variant="paragraph9" color="color-gray-140">
                {availableAmountLabel}
              </Typography>
              <div className="flex items-center max-w-[6.95rem] relative calculator-payout-value">
                {availableCredit ? (
                  <>
                    <Typography
                      variant="paragraph10"
                      bold
                      color="color-blue-180"
                      className="ellipsis tooltip-icon"
                    >
                      {availableCredit}
                    </Typography>
                    {isCreditTruncated && (
                      <div className="tooltip flex p-[0.75rem] justify-end items-end self-stretch bg-[#333333] rounded shadow-md absolute z-50 bottom-[1.7rem] left-[2rem]">
                        <Typography variant="paragraph10" color="color-white-100">
                          {availableCredit}
                        </Typography>
                      </div>
                    )}
                  </>
                ) : (
                  <SvgIcon
                    className="transform rotate-90"
                    name="more_vert"
                    width="1rem"
                    height="1rem"
                    fill="color-gray-40"
                  />
                )}
              </div>
            </div>
            {shouldShowMaxSubsidy && (
              <div className="flex justify-center items-center gap-[0.125rem]">
                <Typography variant="paragraph9" color="color-gray-140">
                  {maxSubsidyLabel}
                </Typography>
                <div className="flex items-center max-w-[6.75rem] calculator-payout-value">
                  {maxSubsidy ? (
                    <>
                      <Typography
                        className="ellipsis tooltip-icon"
                        variant="paragraph10"
                        bold
                        color="color-blue-180"
                      >
                        {maxSubsidy}
                      </Typography>
                      {isSubsidyTruncated && (
                        <div className="tooltip flex p-3 justify-end items-end self-stretch bg-[#333333] rounded shadow-md absolute z-50 bottom-[1.7rem] left-[2rem]">
                          <Typography variant="paragraph12" color="color-white-100">
                            {maxSubsidy}
                          </Typography>
                        </div>
                      )}

                      {!isCustomSchedule && (
                        <Button
                          size="xx-small"
                          backgroundColor="tertiary"
                          type="button"
                          onClick={handleSubsidyApply}
                          className="ml-1"
                        >
                          Apply
                        </Button>
                      )}
                    </>
                  ) : (
                    <SvgIcon
                      className="transform rotate-90 shrink-0"
                      name="more_vert"
                      width="1rem"
                      height="1rem"
                      fill="color-gray-40"
                    />
                  )}
                </div>
              </div>
            )}
            {shouldRenderInterestRate && (
              <div className="flex justify-center items-center gap-[0.125rem]">
                <InterestRateSelect
                  name="interestRate"
                  options={formattedInterestRateOptions}
                  placeholder="Select"
                  label="Interest rate"
                  disabled={shouldDisableInterestRateSelect}
                  dropdownMode={shouldShowInterestRateDropdownMode}
                  shouldOpenDropdownOnBottom={shouldOpenInterestRateDropdownBelow}
                  onChange={onInterestRateValueChange}
                  defaultValue={defaultInterestRate}
                  lockedMode={isCustomSchedule}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default Calculator;
