import { APPRAISAL_STATES } from '../pages/CommonWidgetV2/commonWidget.constants';

interface WidgetData {
  appraisalState?: string;
  previouslyApproved?: boolean;
}

interface TabVisibilityOptions {
  installmentTabWidgetData: WidgetData;
  deferTabWidgetData: WidgetData;
  appraisalState: string;
  sendForSignatureTabWidgetData?: WidgetData;
}

interface TabVisibilityResult {
  showDeferTab: boolean;
  showInstallmentTab: boolean;
}

export const getTabVisibility = ({
  sendForSignatureTabWidgetData,
  installmentTabWidgetData,
  deferTabWidgetData,
  appraisalState,
}: TabVisibilityOptions): TabVisibilityResult => {
  // Helper conditions for showDeferTab
  const isInstallmentDeclinedAndDeferApproved =
    installmentTabWidgetData?.appraisalState === APPRAISAL_STATES.DECLINED &&
    deferTabWidgetData?.appraisalState === APPRAISAL_STATES.APPROVED;

  const isInstallmentDeclinedAndDeferExpiredApproved =
    installmentTabWidgetData?.appraisalState === APPRAISAL_STATES.EXPIRED &&
    deferTabWidgetData?.appraisalState === APPRAISAL_STATES.EXPIRED &&
    !installmentTabWidgetData?.previouslyApproved &&
    (deferTabWidgetData?.previouslyApproved ?? false);

  const isDeferNeedInformation =
    deferTabWidgetData?.appraisalState === APPRAISAL_STATES.NEED_INFORMATION;

  const showDeferTab =
    isInstallmentDeclinedAndDeferApproved ||
    isDeferNeedInformation ||
    isInstallmentDeclinedAndDeferExpiredApproved;

  // Helper conditions for showInstallmentTab
  const isInstallmentApproved =
    installmentTabWidgetData?.appraisalState === APPRAISAL_STATES.APPROVED;

  const isInstallmentNeedInformation =
    installmentTabWidgetData?.appraisalState === APPRAISAL_STATES.NEED_INFORMATION;

  const isCreditInReview = appraisalState === APPRAISAL_STATES.CREDIT_IN_REVIEW;

  // Here we are keeping track of the previously approved state for the installment tab
  const isInstallmentExpiredApproved =
    installmentTabWidgetData?.appraisalState === APPRAISAL_STATES.EXPIRED &&
    (installmentTabWidgetData?.previouslyApproved ?? false);

  const isNewCustomer =
    installmentTabWidgetData?.appraisalState === '' &&
    deferTabWidgetData?.appraisalState === '' &&
    sendForSignatureTabWidgetData?.appraisalState === '';

  const areBothDeclined =
    installmentTabWidgetData?.appraisalState === APPRAISAL_STATES.DECLINED &&
    deferTabWidgetData?.appraisalState === APPRAISAL_STATES.DECLINED;

  const showInstallmentTab =
    isInstallmentApproved ||
    isInstallmentNeedInformation ||
    isCreditInReview ||
    isNewCustomer ||
    areBothDeclined ||
    isInstallmentExpiredApproved;

  return { showDeferTab, showInstallmentTab };
};
