import { gql } from '@apollo/client';

export const GET_CUSTOMERS = gql`
  query getCustomers(
    $first: Int
    $after: String
    $name: String
    $ownerName: String
    $vendorName: String
    $status: String
    $loanDecision: String
    $companyNumber: String
    $creditCondition: String
    $decisionStartDate: ISO8601Date
    $decisionEndDate: ISO8601Date
    $vaildUntilStartDate: ISO8601Date
    $validUntilEndDate: ISO8601Date
    $creditStatus: String
    $isResellerUser: Boolean!
  ) {
    buyers(
      first: $first
      after: $after
      name: $name
      ownerName: $ownerName
      vendorName: $vendorName
      status: $status
      loanDecision: $loanDecision
      companyNumber: $companyNumber
      creditCondition: $creditCondition
      decisionStartDate: $decisionStartDate
      decisionEndDate: $decisionEndDate
      vaildUntilStartDate: $vaildUntilStartDate
      validUntilEndDate: $validUntilEndDate
      creditStatus: $creditStatus
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          number
          status
          updatedAt
          formattedBuyerUtilizedCredit
          buyerRelationToVendor @include(if: $isResellerUser)
          seller @include(if: $isResellerUser) {
            name
            number
            resellerPermissions
          }
          creditCheck {
            loanDecision
            allowedPaymentTerms
            allowedBillingFrequencies
            formattedAllowedPaymentTerms
            expired
            formattedExpireAt
            currency
            creditConditionsForApproval
            updatedAt
            createdAt
            preQualified
            preQualifiedOfferExpired
          }
          accountExecutive {
            id
            fullName
            firstName
            lastName
            email
            phone
            title
            number
            userRole {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_NAMES = gql`
  query getCustomerNames(
    $first: Int
    $after: String
    $name: String
    $companyNumber: String
    $loanDecision: String
    $isResellerUser: Boolean = false
  ) {
    buyers(
      first: $first
      after: $after
      name: $name
      companyNumber: $companyNumber
      loanDecision: $loanDecision
    ) {
      nodes {
        id
        name
        number
        buyerRelationToVendor @include(if: $isResellerUser)
        seller @include(if: $isResellerUser) {
          resellerPermissions
        }
      }
    }
  }
`;

export const GET_CUSTOMER_BY_NUMBER = gql`
  query getCustomerByNumber($number: String!, $includeBuyerOrderTypes: Boolean = false) {
    company(number: $number) {
      buyerAvailableOrderTypes @include(if: $includeBuyerOrderTypes)
      number
      status
      name
      defaultAddressId
      street
      city
      state
      country
      zip
      entityType
      annualRevenue
      employeeCount
      maxUnits
      formattedRequestedAmount
      requestedTerm
      pricingExpired
      createdAt
      updatedAt
      buyerMinAllowedOrderAmount
      formattedBuyerMinAllowedOrderAmount
      availablePaymentMethods
      buyerRelationToVendor
      pullCrmData
      seller {
        name
        resellerPermissions
        hideResellerOrderDetails
        useResellerWorkflow
        product {
          ...ProductDetails
        }
      }
      buyerOrders {
        number
        amount
        formattedAmount
        currency
        formattedFee
        term
        paymentTerm
        formattedTerm
        status
        vendorState
        updatedAt
        canUploadFundingInvoice
        validUntil
        validForDays
        hideOrderDetailsForCollaborator
        anyPendingInvoicesOnMilestone
        milestones {
          number
          vendorMilestoneStatus
        }
        user {
          fullName
          firstName
          lastName
          email
          phone
          title
        }
      }
      users {
        number
        fullName
        email
        phone
        formattedPhone
        isOwner
        primary
        receiveInvoice
      }
      accountStatements {
        amount
        number
        url
        formattedAmount
        statementDate
      }
      paymentScheduleItems {
        dueDate
        payment
        formattedPayment
        formattedTotalDue
        invoices {
          number
        }
        payments {
          number
        }
      }
      primaryUser {
        number
        firstName
        lastName
        email
        phone
        title
        formattedPhone
      }
      creditCheck {
        id
        number
        currentStepDetails
        emailHistory
        moreInfo
        approvedCreditTermsFormatted
        pgRequired
        conditionalToPg
        maxTerm
        applicationRequired
        loanDecision
        expired
        allowedBillingFrequencies
        formattedAllowedPaymentTerms
        formattedExpireAt
        currency
        className
        conditionalToPg
        userDocuments {
          id
          type
          url
          filename
          createdAt
          formattedCreatedAt
        }
        changeRequest {
          status
          id
        }
      }
      buyerUtilizedCredit
      formattedBuyerUtilizedCredit
      buyerRemainingCredit
      formattedBuyerRemainingCredit
      buyerPaymentsCollectedOverPredictedPayments
      vendorAvailablePaymentOptions
      businessName
      invoiceEnabled
      accountExecutive {
        firstName
        lastName
        email
        phone
        title
      }
    }
  }
`;

export const GET_CUSTOMER_ONLY_BY_NUMBER = gql`
  query getCustomerOnlyByNumber($number: String!) {
    company(number: $number) {
      number
      status
      name
      defaultAddressId
      street
      city
      state
      zip
      entityType
      annualRevenue
      employeeCount
      maxUnits
      formattedRequestedAmount
      requestedTerm
      pricingExpired
      createdAt
      buyerRelationToVendor
      buyerMinAllowedOrderAmount
      formattedBuyerMinAllowedOrderAmount
      availablePaymentMethods
      updatedAt
      currentBuyerOrdersCount
      formattedTotalBalance
      formattedTotalRemainingBalance
      totalPaymentsCollectedCount
      pricingExpiryDate
      seller {
        name
        resellerPermissions
        hideResellerOrderDetails
        useResellerWorkflow
        product {
          ...ProductDetails
        }
      }
      primaryUser {
        number
        firstName
        lastName
        email
        phone
        title
        formattedPhone
      }
      creditCheck {
        id
        number
        currentStepDetails
        emailHistory
        moreInfo
        approvedCreditTermsFormatted
        pgRequired
        creditConditionsForApproval
        conditionalToPg
        maxTerm
        applicationRequired
        loanDecision
        expired
        allowedBillingFrequencies
        formattedExpireAt
        currency
        formattedAllowedPaymentTerms
        className
        conditionalToPg
        userDocuments {
          id
          type
          url
          filename
          createdAt
          formattedCreatedAt
        }
        changeRequest {
          status
          id
        }
        creditApplication {
          state
        }
      }
      buyerUtilizedCredit
      formattedBuyerUtilizedCredit
      buyerRemainingCredit
      formattedBuyerRemainingCredit
      buyerPaymentsCollectedOverPredictedPayments
      crmUrl
      vendorAvailablePaymentOptions
      businessName
      invoiceEnabled
      accountExecutive {
        firstName
        lastName
        email
        phone
        title
      }
    }
  }
`;

export const CREATE_CREDIT_CHECK = gql`
  mutation createCreditCheck($companyNumber: String!) {
    createCreditCheck(companyNumber: $companyNumber) {
      id
    }
  }
`;

export const SEND_CREDIT_APPLICATION = gql`
  mutation sendCreditApplication(
    $companyNumber: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $message: String!
  ) {
    sendCreditApplication(
      companyNumber: $companyNumber
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      message: $message
    ) {
      id
    }
  }
`;

export const GET_CUSTOMER_NAME_BY_NUMBER = gql`
  query getCustomerNameByNumber($number: String!) {
    company(number: $number) {
      id
      number
      name
      buyerMinAllowedOrderAmount
      formattedBuyerMinAllowedOrderAmount
      creditCheck {
        emailHistory
        isCustomSchedule
      }
      seller {
        product {
          availablePaymentFrequencies
          availablePaymentTerms
          availableOrderTypes
          availableContractLengths
        }
      }
    }
  }
`;

export const GET_CUSTOMER_FOR_MODIFY_REQUEST = gql`
  query getCustomerNameByNumber($number: String!) {
    company(number: $number) {
      name
      canadianBuyer
      creditCheck {
        loanDecision
        currency
        isCustomSchedule
      }
      seller {
        product {
          availablePaymentFrequencies
          availablePaymentTerms
          availableOrderTypes
          availableContractLengths
        }
      }
    }
  }
`;

export const GET_CUSTOMER_FOR_MORE_INFO = gql`
  query getCustomerForMoreInfo($number: String!) {
    company(number: $number) {
      name
      businessName
      entityInformationEin
      dateStarted
      annualRevenue
      street
      city
      state
      zip
      country
      creditCheck {
        number
        className
        id
        currency
        creditApplication {
          id
          financialDocs
          number
          applicationSteps
          progress
          state
          applicationStepsDetails
          salesQuoteAdditionalNotes
          financialDocsAdditionalNotes
          customerApplicationOpenedAt
        }
      }
      primaryUser {
        number
        firstName
        lastName
        email
        phone
        title
        defaultAddress {
          zip
          state
          city
          street
        }
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $name: String!
    $legalName: String
    $street: String!
    $city: String!
    $state: String!
    $zip: String!
    $country: String!
    $currency: String!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $dunsNumber: String
    $maxUnits: Int
    $requestedAmount: Float
    $requestedTerm: Int
    $crmAccountId: String
    $personalGuarantors: [InputPersonalGuarantor!]
  ) {
    createCustomer(
      name: $name
      legalName: $legalName
      street: $street
      city: $city
      state: $state
      zip: $zip
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      dunsNumber: $dunsNumber
      maxUnits: $maxUnits
      requestedAmount: $requestedAmount
      requestedTerm: $requestedTerm
      crmAccountId: $crmAccountId
      country: $country
      currency: $currency
      personalGuarantors: $personalGuarantors
    ) {
      number
      id
      creditCheck {
        id
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $number: String!
    $name: String
    $entityType: String
    $addressId: ID
    $street: String
    $city: String
    $state: String
    $zip: String
    $ein: String
    $contactNumber: String
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $annualRevenue: Float
    $employeeCount: Int
    $maxUnits: Int
    $requestedAmount: Float
    $requestedTerm: Int
  ) {
    updateCustomer(
      number: $number
      name: $name
      entityType: $entityType
      addressId: $addressId
      street: $street
      city: $city
      state: $state
      zip: $zip
      ein: $ein
      contactNumber: $contactNumber
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      annualRevenue: $annualRevenue
      employeeCount: $employeeCount
      maxUnits: $maxUnits
      requestedAmount: $requestedAmount
      requestedTerm: $requestedTerm
    ) {
      number
    }
  }
`;

export const VALIDATE_CUSTOMER = gql`
  mutation validateNewCustomer(
    $name: String!
    $legalName: String
    $street: String!
    $city: String!
    $state: String!
    $zip: String!
    $country: String!
    $currency: String!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $dunsNumber: String
    $maxUnits: Int
    $requestedAmount: Float
    $requestedTerm: Int
    $crmAccountId: String
  ) {
    validateNewCustomer(
      name: $name
      legalName: $legalName
      street: $street
      city: $city
      state: $state
      zip: $zip
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      dunsNumber: $dunsNumber
      maxUnits: $maxUnits
      requestedAmount: $requestedAmount
      requestedTerm: $requestedTerm
      crmAccountId: $crmAccountId
      country: $country
      currency: $currency
    ) {
      user {
        company {
          defaultPaymentMethod {
            id
            paymentMode
            invoiceType
          }
        }
      }
    }
  }
`;

export const CURRENT_PAYMENT_OPTIONS = gql`
  query currentPaymentOptions($customerNumber: String!) {
    currentPaymentOptions(customerNumber: $customerNumber)
  }
`;

export const CREATE_CREDIT_CHECK_CHANGE_REQUEST = gql`
  mutation (
    $companyNumber: String!
    $modificationReason: String
    $totalContractAmount: Float!
    $term: Float!
    $billingFrequency: String!
    $paymentTerm: Int!
    $paymentOption: String
  ) {
    createCreditCheckChangeRequest(
      companyNumber: $companyNumber
      modificationReason: $modificationReason
      totalContractAmount: $totalContractAmount
      term: $term
      billingFrequency: $billingFrequency
      paymentTerm: $paymentTerm
      paymentOption: $paymentOption
    ) {
      id
      number
      creditApplication {
        id
        financialDocs
        applicationSteps
      }
    }
  }
`;

export const GENERATE_PROPOSAL = gql`
  mutation generateProposal(
    $customerNumber: String!
    $opportunityAmount: Float!
    $billingFrequency: String!
    $paymentTerm: Int
    $term: Int
    $blindDiscount: Float
    $isDollar: Boolean
    $spiffRate: Float
    $offeredBuyerInterestRate: Float
    $applyOfferedBuyerInterestRate: Boolean
  ) {
    generateProposal(
      customerNumber: $customerNumber
      opportunityAmount: $opportunityAmount
      billingFrequency: $billingFrequency
      paymentTerm: $paymentTerm
      term: $term
      blindDiscount: $blindDiscount
      isDollar: $isDollar
      spiffRate: $spiffRate
      offeredBuyerInterestRate: $offeredBuyerInterestRate
      applyOfferedBuyerInterestRate: $applyOfferedBuyerInterestRate
    ) {
      id
      number
      encodedProposal
    }
  }
`;

export const GENERATE_PRE_QUALIFIED_AGREEMENT = gql`
  mutation generatePreQualifiedAgreement(
    $customerNumber: String!
    $opportunityAmount: Float!
    $billingFrequency: String!
    $paymentTerm: Int!
    $term: Int!
    $blindDiscount: Float!
    $isDollar: Boolean!
    $spiffRate: Float!
    $offeredBuyerInterestRate: Float
    $applyOfferedBuyerInterestRate: Boolean
  ) {
    generatePreQualifiedAgreement(
      customerNumber: $customerNumber
      opportunityAmount: $opportunityAmount
      billingFrequency: $billingFrequency
      paymentTerm: $paymentTerm
      term: $term
      blindDiscount: $blindDiscount
      isDollar: $isDollar
      spiffRate: $spiffRate
      offeredBuyerInterestRate: $offeredBuyerInterestRate
      applyOfferedBuyerInterestRate: $applyOfferedBuyerInterestRate
    )
  }
`;

export const GENERATE_PAYMENT_PLAN = gql`
  mutation generatePaymentPlan(
    $customerNumber: String!
    $billingFrequency: String!
    $blindDiscount: Float!
    $isDollar: Boolean!
    $opportunityAmount: Float!
    $paymentTerm: Int!
    $term: Int!
    $spiffRate: Float!
  ) {
    generatePaymentPlan(
      customerNumber: $customerNumber
      billingFrequency: $billingFrequency
      blindDiscount: $blindDiscount
      isDollar: $isDollar
      opportunityAmount: $opportunityAmount
      paymentTerm: $paymentTerm
      term: $term
      spiffRate: $spiffRate
    )
  }
`;

export const REASSIGN_CUSTOMER_ACCOUNT_OWNER = gql`
  mutation changeCustomerAccountExecutive($number: String!, $userNumber: String!) {
    changeCustomerAccountExecutive(number: $number, userNumber: $userNumber) {
      id
    }
  }
`;

export const BUMP_PRIORITY_TO_HIGH = gql`
  mutation bumpPriorityToHigh($number: String!) {
    bumpPriorityToHigh(number: $number) {
      id
    }
  }
`;

export const REFRESH_PRICING = gql`
  mutation refreshPricing($companyNumber: String!) {
    refreshPricing(companyNumber: $companyNumber) {
      id
    }
  }
`;

export const UPDATE_BUSINESS_DETAILS = gql`
  mutation updateBusinessDetailsCreditAppraisal(
    $businessName: String!
    $dba: String!
    $street: String!
    $city: String!
    $state: String!
    $zip: String!
    $ein: String!
    $annualRevenue: Float!
    $dateStarted: ISO8601Date!
  ) {
    updateBusinessDetailsCreditAppraisal(
      businessName: $businessName
      dba: $dba
      street: $street
      city: $city
      state: $state
      zip: $zip
      ein: $ein
      annualRevenue: $annualRevenue
      dateStarted: $dateStarted
    ) {
      id
    }
  }
`;
