import { useMemo, ReactElement, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { Card, Typography } from '@vartanainc/design-system';
import { useReactiveVar } from '@apollo/client';
import { requestModelAmountVar, sessionVar } from '../../../../graphql/cache';
import FormInput from '../../../../components/FormInput';

import TextInputField from '../../../../designSystem/TextInput/TextInputField';
import AddressInputField from '../../../../designSystem/AddressInput/AddressInput';
import CustomDropdown from '../../../../designSystem/FormikDropdownInput/FormikDropdownInput';
import MultiFileUpload from '../../../../components/MultiFileUpload';
import { useSetAddressFields } from '../../../../utils/hooks';
import {
  getCountryOptions,
  getStateOptions,
  getTermOptionsForCustomerCreation,
  handleCountrySelect,
} from '../../../../utils/helpers';
import MoneyInputField from '../../../../designSystem/MoneyInput/MoneyInputField';
import MultiCountryZipInput from '../../../../designSystem/MultiCountryZipInput/MultiCountryZipInput';
import { COUNTRY_TO_CURRENCY_MAP } from '../../../../static';
import { useSessionValue } from '../../../../hooks/SessionValue';

export interface InitialCustomerFormValues {
  number: string | null;
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  employeeCount: string;
  annualRevenue: string;
  maxUnits: string;
  requestedAmount: string;
  requestedTerm: string;
  contactNumber: string | null;
  addressId: string | null;
  country: string;
  currency: string;
}

interface CustomerFormCrm {
  handleCountryChange: () => void;
}

export default function CustomerFormCRM({
  handleCountryChange,
}: CustomerFormCrm): ReactElement {
  const session = useReactiveVar(sessionVar);
  const showCountryField = useSessionValue<boolean>(
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const isSalesQuoteCompulsory = useSessionValue<boolean>(
    'session.user.company.salesQuoteRequiredAtCustomerCreation',
    false
  );
  const supportedCountries = useSessionValue<string[]>(
    'session.user.company.product.enabledCountries',
    []
  );
  const requestModel = useReactiveVar(requestModelAmountVar);

  const formikBag = useFormikContext<InitialCustomerFormValues>();
  const { isSubmitting, setFieldValue, validateField, handleSubmit } = formikBag;

  useEffect(() => {
    // whenever canada is selected as country, remove pay in full option from requested terms
    handleCountryChange();

    // we should run this effect only when the country is toggled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.values.country]);

  useEffect(() => {
    if (showCountryField) {
      const countryCode = formikBag.values.country;
      const currency = COUNTRY_TO_CURRENCY_MAP[countryCode];
      if (currency) formikBag.setFieldValue('currency', currency);
    }
    // only run this effect when country changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.values.country]);

  const setSelectedPlace = useSetAddressFields(
    setFieldValue,
    validateField,
    showCountryField
  );

  const requestedTermOptions = getTermOptionsForCustomerCreation(session);

  const requestedQuantity = useMemo(() => {
    let input: ReactElement | null;
    if (requestModel) {
      input = (
        <>
          <div className="flex flex-row gap-4">
            <MoneyInputField
              id="requestedAmount"
              name="requestedAmount"
              label="Requested amount"
              prefix="$"
              placeholder=""
              suffix={null}
              minAllowedValue={null}
              maxAllowedValue={null}
              showFieldError={null}
            />

            <CustomDropdown
              id="requestedTerm"
              name="requestedTerm"
              label="Requested term"
              options={requestedTermOptions}
              placeholder="Select option"
            />
          </div>
        </>
      );
    } else {
      const inputFieldProps = {
        id: 'maxUnits',
        name: 'maxUnits',
        label: 'Max units',
        type: 'number',
        noDecimal: true,
      };
      input = (
        <div className="flex flex-row">
          <TextInputField {...inputFieldProps} ref={null} />
        </div>
      );
    }
    return input;
  }, [requestModel, requestedTermOptions]);

  return (
    <Card
      tabindex={0}
      variant="fullWidth"
      title={(
        <div className="grid gap-2.5">
          <div className="flex gap-2 items-center">
            <Typography variant="heading18" color="color-black-100">
              Business Information
            </Typography>
          </div>
        </div>
      )}
      content={(
        <form onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <div className="customer-form-container grid grid-cols-1 divide-y divide-vartana-gray-30">
              <div className="flex flex-col gap-4">
                <FormInput className="hidden" name="number" type="hidden" />
                <div className="flex flex-row">
                  <TextInputField
                    {...{
                      id: 'name',
                      name: 'name',
                      label: 'Company name',
                    }}
                    ref={null}
                  />
                </div>

                <FormInput className="hidden" name="addressId" type="hidden" />

                <AddressInputField
                  name="street"
                  label="Address"
                  afterPlaceSelect={(tempSelectedPlace) =>
                    setSelectedPlace(tempSelectedPlace)}
                  countries={supportedCountries}
                  id={null}
                />

                <div className="flex flex-row gap-4">
                  <div className="w-1/2">
                    <TextInputField
                      {...{
                        id: 'city',
                        name: 'city',
                        label: 'City',
                      }}
                      ref={null}
                    />
                  </div>
                  <div className="w-1/2 flex flex-row gap-4">
                    <CustomDropdown
                      id="state"
                      name="state"
                      label="State"
                      options={getStateOptions(supportedCountries) || []}
                      placeholder="Select"
                    />
                    <MultiCountryZipInput id="zip" name="zip" label="Zip" />
                  </div>
                </div>
                {showCountryField && (
                  <div className="flex flex-row gap-4">
                    <CustomDropdown
                      id="country"
                      name="country"
                      label="Country"
                      options={getCountryOptions(supportedCountries) || []}
                      placeholder="Select"
                      onChange={(e) => handleCountrySelect(formikBag, e.value)}
                    />
                    <TextInputField
                      {...{
                        id: 'currency',
                        name: 'currency',
                        label: 'Currency',
                        disabled: true,
                      }}
                      ref={null}
                    />
                  </div>
                )}
                {requestedQuantity}
                <div className="flex flex-col gap-1">
                  <Typography variant="paragraph12" color="color-gray-140">
                    Sales quote or other documents
                    {!isSalesQuoteCompulsory && ' (optional)'}
                  </Typography>
                  <MultiFileUpload
                    uploadText="Click or drag PDF files to upload"
                    name="docs"
                    acceptMultipleFiles
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    />
  );
}
