import { Typography } from '@vartanainc/design-system';
import { formattedDateTime } from '../../utils/helpers';
import { ReactComponent as FileIcon } from '../../assets/file.svg';
import { ReactComponent as FileIconDisabled } from '../../assets/file_disabled.svg';
import Checkbox from '../Checkbox/Checkbox';

interface FileItemProps {
  id: string;
  fileName: string;
  isChecked: boolean;
  isDisabled: boolean;
  onClick: () => void;
  FileTimestamp: string;
}

const FileItem: React.FC<FileItemProps> = ({
  id,
  fileName,
  isChecked,
  isDisabled,
  onClick,
  FileTimestamp, // created at timestamp for the file
}) => {
  const handleFileClick = (): void => {
    if (!isDisabled) {
      onClick();
    }
  };

  const buttonClassnames = `flex items-center justify-between w-full p-4 rounded-md border-[1px] ${
    isDisabled ? ' bg-vartana-gray-30' : ''
  }`;

  return (
    <button className={buttonClassnames} onClick={handleFileClick} disabled={isDisabled}>
      <div className={`flex ${FileTimestamp ? 'items-start' : 'items-center'} gap-1`}>
        {isDisabled ? <FileIconDisabled /> : <FileIcon />}
        <div className="flex flex-col items-start gap-1">
          <Typography
            variant="paragraph14"
            color={isDisabled ? 'color-gray-110' : 'color-black-100'}
            className="text-ellipsis whitespace-nowrap max-w-[32rem] overflow-hidden"
          >
            {fileName}
          </Typography>
          {!!FileTimestamp && (
            <Typography variant="paragraph12" color="color-gray-110">
              {formattedDateTime(FileTimestamp)}
            </Typography>
          )}
        </div>
      </div>
      <Checkbox checked={isChecked} disabled={isDisabled} onCheck={onClick} />
    </button>
  );
};

export default FileItem;
